import React, { useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
function AddEditCategoryValueModal(props) {
    useEffect(() => {
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, []);

    var data = props.categories.filter(x => x.id === props.categoryId);
    return (
        <>
            <Form action="" className="form" method="">
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Name</label>
                            <Form.Control
                                name="name"
                                placeholder="Name"
                                type="text"
                                value={props.data.name}
                                onChange={(e) => props.handleChange("name", e.target.value)}
                            />
                        </Form.Group>
                        {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>}
                    </Col>
                </Row>
                <Row>
                    {props.categoryId == 14 ?
                        <Col className="pr-1" md="6">
                            <Form.Group>
                                <label>Value</label>
                                <Form.Control
                                    name="value"
                                    placeholder="Value"
                                    type="text"
                                    value={props.data.value}
                                    onChange={(e) => props.handleChange("value", e.target.value)}
                                />
                            </Form.Group>
                            {props.error.value && <label style={{ color: "red" }} className='err'>{props.error.value}</label>}
                        </Col>                    
                    :
                        <Col className="pr-1" md="6">
                            <Form.Group>
                                <label>Code</label>
                                <Form.Control
                                    name="code"
                                    placeholder="Code"
                                    type="text"
                                    value={props.data.code}
                                    onChange={(e) => props.handleChange("code", e.target.value)}
                                />
                            </Form.Group>
                            {props.error.code && <label style={{ color: "red" }} className='err'>{props.error.code}</label>}
                        </Col>                    
                    }
                </Row>
                {props.data.id > 0 && props.categoryGroups.length > 0 &&
                    <Row>
                        <Col className="pr-1" md="6">
                            <Form.Group>
                                <label>Category Group</label>
                                <CustomSelect
                                    placeholder="Category Name"
                                    data={props.categoryGroups}
                                    handleChange={(e) => props.handleChangeCategoryDropdown("categoryGroupId", e.value, "categoryGroupName", e.label)}
                                    withAll={false}
                                    value={props.data.categoryGroupId}
                                />
                            </Form.Group>

                        </Col>
                    </Row>
                }
                {
                    data[0].requiresSupplierId && props.categoryId === 2 &&
                    <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Suppliers</label>
                            <CustomSelect
                                placeholder="Supplier"
                                data={props.suppliers}
                                handleChange={(e) => props.handleChangeCategoryDropdown("supplierId", e.value, "supplierName", e.label)}
                                withAll={false}
                                value={props.data.supplierId}
                            />
                        </Form.Group>
                        {props.error.supplierId && <label style={{ color: "red" }} className='err'>{props.error.supplierId}</label>}
                    </Col>
                    </Row>
                }
                {
                    data[0].requiresSizeGroupId && props.categoryId === 3 &&
                    <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Size Group</label>
                            <CustomSelect
                                placeholder="Size Group"
                                data={props.catrgorySize}
                                handleChange={(e) => props.handleChangeCategoryDropdown("sizeGroupId", e.value, "sizeGroupName", e.label)}
                                withAll={false}
                                value={props.data.sizeGroupId}
                            />
                        </Form.Group>
                        {props.error.sizeGroupId && <label style={{ color: "red" }} className='err'>{props.error.sizeGroupId}</label>}
                    </Col>
                    </Row>
                }

                <Button
                    className="btn-fill float-end"
                    variant="primary"
                    onClick={props.handleSave}
                >
                    Save
                </Button>

                <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={props.handleCancel}
                >
                    Cancel
                </Button>
                <div className="clearfix"></div>
            </Form >
        </>
    );
}

export default AddEditCategoryValueModal;

import React, { useState } from "react";
import CustomSelect from "components/Shared/CustomSelect";
import {
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";

function AddEditPurchaseOrderMappingModal(props) {
    const GetDropdownList = props.categoriesDropdown;
    return (
        <>
            <Form action="" className="form" method="">
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Name</label>
                            <Form.Control
                                name="name"
                                placeholder="Name"
                                type="text"
                                value={props.purchaseOrderMappingData.name}
                                onChange={(e) => props.handleChange("name", e.target.value)}
                                onBlur={(e) => props.validateInput("name", e.target.value)}
                            />
                        </Form.Group>
                        {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Column Start</label>
                            <Form.Control
                                name="column number"
                                placeholder="Column number"
                                type="number"
                                value={props.purchaseOrderMappingData.columnStart}
                                onChange={(e) => props.handleChange("columnStart", e.target.value)}
                                onBlur={(e) => props.validateInput("columnStart", e.target.value)}
                            />
                        </Form.Group>
                        {props.error.columnStart && <label style={{ color: "red" }} className='err'>{props.error.columnStart}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <CustomSelect
                                title="Category Id"
                                placeholder="Category Id"
                                id={props.purchaseOrderMappingData.categoryValueId}
                                data={GetDropdownList}
                                value={props.purchaseOrderMappingData.categoryValueId}
                                handleChange={(e) => props.handleChange("categoryValueId", e.value)}
                            />
                        </Form.Group>
                        {props.error.categoryValueId && <label style={{ color: "red" }} className='err'>{props.error.categoryValueId}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Formula</label>
                            <Form.Control
                                name="formula"
                                placeholder="Formula"
                                type="text"
                                value={props.purchaseOrderMappingData.formula}
                                onChange={(e) => props.handleChange("formula", e.target.value)}
                                onBlur={(e) => props.validateInput("formula", e.target.value)}
                            />
                        </Form.Group>
                        {props.error.columnStart && <label style={{ color: "red" }} className='err'>{props.error.columnStart}</label>}
                    </Col>
                </Row>
                {
                    !props.showEdit &&
                    <Button
                        className="btn-fill float-end"
                        variant="primary"
                        onClick={() => props.handleAddPurchaseOrder()}
                    >
                        Save
                    </Button>
                }
                {props.showEdit &&
                    <Button
                        className="btn-fill float-end"
                        variant="primary"
                        onClick={() => props.handleUpdatePurchaseOrder(props.PurchaseOrderId)}
                    >
                        Update
                    </Button>
                }
                <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={props.handleCancel}
                >
                    Cancel
                </Button>
                <div className="clearfix"></div>
            </Form >
        </>
    );
}

export default AddEditPurchaseOrderMappingModal;
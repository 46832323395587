import React from "react";
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner, Form
} from "react-bootstrap";
import CustomSearchBox from "components/Shared/CustomSearchBox";
function MovementsProductTable(props) {
    const SearchStyle = {
        marginTop: "-35px",
        marginLeft: "245px",
    };
    const btnStyle = {
        marginTop: "-50px",
        marginLeft: "61%",
    }
    const renderTableData = () => {
        return props.MovementsProductData.length > 0 && props.MovementsProductData.map((log, index) => {
            const { productName, sku, productId, id, movementId, quantity, sent, received, bookedInUserName, bookedOutUserName } = log
            return (
                <tr key={`MovementsProduct${index}`}>
                    <td>{productId}</td>
                    <td onClick={() => props.handleGetItemCard(productId)}>
                        <a href="#id">{sku}</a>
                    </td>
                    <td>{productName}</td>
                    <td>{quantity}</td>
                    <td>{sent}</td>
                    <td>{bookedOutUserName}</td>
                    <td>{received}</td>
                    <td>{bookedInUserName}</td>
                </tr>
            )
        })

    }
    return (
        <Card className="strpied-tabled-with-hover">
            <Card.Header>
                <Card.Title as="h4">Movements Products</Card.Title>
                <Col md="3" style={SearchStyle}>
                    <CustomSearchBox
                        placeholder="Search"
                        value={props.search}
                        handleChange={(e) => props.handleSearchChange(e)}
                        handleClear={() => props.handleSearchChange("")}
                    />
                </Col>
                {props.SingleMovement.typeId === 3 &&
                    <OverlayTrigger
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        overlay={
                            <Tooltip id="tooltip-255158527">Add Movement Product</Tooltip>
                        }
                    >
                        <Button style={SearchStyle}
                            className="btn-fill float-end"
                            variant="primary"
                            onClick={() => props.handleAddMovementProduct()}
                        >
                            Add
                        </Button>
                    </OverlayTrigger>
                }
            </Card.Header>
            <Card.Body className="table-responsive p-0">
                {props.isLoading ?
                    <div style={{ height: 120 }} className="d-flex justify-content-center align-items-center text-center">
                        <div>
                            <Spinner animation="border" />
                            <p>Loading...</p>
                        </div>
                    </div> :
                    <>
                        {(props.MovementsProductData && props.MovementsProductData.length == 0) &&
                            (<p
                                style={{ height: 120 }}
                                className="d-flex justify-content-center align-items-center text-center"
                            >
                                No data found.
                            </p>)}
                    </>
                }
                {(props.MovementsProductData.length !== 0) && !props.isLoading &&
                    <Col md="20" >
                        <Table className="table-hover table-striped w-full">
                            <thead>
                                <tr>
                                    <th>p#</th>
                                    <th>Sku</th>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Picked</th>
                                    <th>Booked Out</th>
                                    <th>Recieved</th>
                                    <th>Booked In</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                }
            </Card.Body>
        </Card>
    )
} export default MovementsProductTable
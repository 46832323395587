import React, { useState, useEffect } from "react";
//import Moment from 'react-moment';
// react component used to create charts
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Card,
    Form,
    Container,
    Row,
    Col,
    Button
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'

import UserTable from '../../components/UserManagement/UserTable';
import UserPage from '../../components/UserManagement/UserPage';
import CustomModal from '../../components/Shared/CustomModal';

import { ADMIN_USERMANAGEMENT_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

function UserManagement() {

    const user = useSelector((state) => state.user);
    // ### STATE ###
    const [error, setError] = useState({
        username: '',
        email: '',
        role: '',
        firstName: '',
        lastName: '',
        location: ''
    })
    
    const [roles, setRoles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [userId, setUserId] = useState(0);
    const [selectedUser, setSelectedUser] = useState({});
    const [isUsername, setUsername] = useState(false);
    const [isEmail, setIsEmail] = useState(false);


    const [showAddEditModal, setShowAddEditModal] = useState(false);

    //hooks
    const handleCloseAddEditModal = () => setShowAddEditModal(false);

    // ### HANDLERS ###

    const clearAddUserValidation = () => {
        setError({
            username: '',
            email: '',
            role: '',
            firstname: '',
            lastname: '',
            location: ''

        })
    };

    const handleAdd = () => {
        clearAddUserValidation()
        setUserId(0);
        setSelectedUser({
            id: 0,
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            roleId: 0,
            roleName: "",
            locationId: 0,
            locationName: "",
            permissions: [],
            avatar: "",
        });
        setShowAddEditModal(true);
        setIsEdit(true);
    }
    const handleShowUser = async (id) => {
        clearAddUserValidation()
        try {
            let url = `/user/getsingle?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const user = await response.json();

            setUserId(user.id);
            setSelectedUser(user);
            setShowAddEditModal(true);
            setIsEdit(false);
        } catch (err) {
        }
    }

    const handleEdit = async () => {
        try {
            setIsEdit(true);
        } catch (err) {
        }
    }

    const handleShowSave = () => {
        showSweetAlert('warning', `Are you sure to save the user?`, () => { hideSave() });
    }

    const hideSave = () => {
        
        handleSave()
    }
    const handleChange = (name, value) => {

        if (name == "roleId") {
            error.role = "";
        }
        if (name == "locationId") {
            error.location = "";
        }
        setSelectedUser({
            ...selectedUser,
            [name]: value
        })
        validateInput(name, value)
    }

    // Apply validation in Input Feilds
    const validateInput = (name, value) => {

        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "username":
                    if (!value) {
                        stateObj[name] = "Please enter a username";
                    }
                    else if (isUsername) {
                        stateObj[name] = "Username Exists";
                    }
                    break;

                case "email":
                    if (!value) {
                        stateObj[name] = "Please enter an email";
                    }
                    else if (isEmail) {
                        stateObj[name] = "Email Exists";
                    }
                    break;
                case "roleId":
                    if (!value) {
                        stateObj[name] = "Please select a role";
                    }
                    break;

                case "lastName":
                    if (!value) {
                        stateObj[name] = "Please enter the lastname";
                    }
                    break;

                case "firstName":
                    if (!value) {
                        stateObj[name] = "Please enter the firstname";
                    }
                    break;
                case "location":
                    if (!value) {
                        stateObj[name] = "Please select a Location";
                    }
                    break;
                default:
                    break;

            }

            return stateObj;
        });
    }

    //Handle save for Add and Edit Users
    const handleSave = async () => {

        if (selectedUser.roleId === 0) {
            error.role = "Please select a role"
        } if (selectedUser.email === "") {
            error.email = "Please enter an email"
        }
        if (selectedUser.username === "") {
            error.username = "Please enter a username"
        }
        if (selectedUser.lastName === "") {
            error.lastName = "Please enter a lastname"
        }
        if (selectedUser.firstName === "") {
            error.firstName = "Please enter a firstname"
        }
        if (selectedUser.locationId === 0) {
            error.location = "Please enter a location"
        }
        try {
            
            // Check Username & Email Exists 
            if (userId === 0 && selectedUser.username != "") {
                const resUsername = await fetch(`/user/usernameexists?username=${selectedUser.username}`);
                const isUsernameExists = await resUsername.json();
                if (isUsernameExists === true) {
                    setUsername(true)
                    error.username = "Username Exists"
                } else {
                    error.username = ""
                }
                if (selectedUser.email != "") {
                    const resEmail = await fetch(`/user/emailexists?email=${selectedUser.email}`);
                    const isEmailExists = await resEmail.json();
                    if (isEmailExists === true) {
                        setIsEmail(true)
                        error.email = "Email Exists"
                    } else {
                        error.email = ""
                    }
                }
            }
            // Add User
            if (userId === 0) {

                if (error.username === "" && error.email === "" && error.role === "" && error.lastName === "" && error.role === "" && error.firstName === "" && error.location === "") {
                    const response = await fetch(`/user/add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                        method: 'post',
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"]
                        ],
                        body: JSON.stringify(selectedUser),
                    });
                    const body = await response.json();
                    if (body) {
                        showSweetAlert('saveSuccess', `User`);
                        await fetchData();
                        handleCloseAddEditModal();
                        return;
                    } else {
                        showSweetAlert('serverError');
                    }
                }
            }
            // Edit User
            if (userId !== 0) {
                if (error.username === "" && error.email === "" && error.role === "") {
                    const response = await fetch(`/user/update?id=${userId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                        method: 'put',
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"]
                        ],
                        body: JSON.stringify(selectedUser),
                    });
                    const body = await response.json();
                    if (body) {
                        showSweetAlert('saveSuccess', `User`);
                        await fetchData();
                        handleCloseAddEditModal();
                    } else {
                        showSweetAlert('serverError');
                    }
                }
            }
        } catch (err) {
        }
    };
    const handleCancel = () => {
        setIsEdit(false);
        hideAlert();
    };

    const handleQr = async (id) => {
        try {
            const response = await fetch(`/user/generatenewqr?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'post' });
            const data = await response.json();

            if (data === true) {
                showSweetAlert('saveSuccess', `User`);
            }

            if (body) {
                showSweetAlert('saveSuccess', `User`);
                await fetchData();
            }

            await fetchData();
        } catch (err) {
        }
    }

    const handlePin = async (id) => {
        try {
            const response = await fetch(`/user/generatenewpin?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'post' });
            const data = await response.json();

            if (data === true) {
                showSweetAlert('saveSuccess', `User`);
            }

            if (body) {
                showSweetAlert('saveSuccess', `User`);
                await fetchData();
            }

            await fetchData();
        } catch (err) {
        }
    }


    const handleDelete = (id) => {
        showSweetAlert('deleteWarning', `user`, () => { successDelete(id) });
    }
    const successDelete = async (id) => {
        try {
            const response = await fetch(`/user/delete?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            showSweetAlert('deleteSuccess', `User`);
            await fetchData();
        } catch (err) {
        }
    };

    const hideAlert = () => {
        
    };

    // ### DATA FETCHES ###

    const fetchRoles = async () => {
        try {
            let url = `/user/getroles?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();

            setRoles(body);
        } catch (err) {
        }
    }
    const fetchLocations = async () => {
        try {
            let url = `/location/getdropdownlist?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };

            });

            setLocations(data);
        } catch (err) {
        }
    }
    const fetchData = async () => {
        try {
            let url = `/user/getall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const users = await response.json();

            setData(users);
        } catch (err) {
        }
    }

    // ### HOOKS ###

    useEffect(() => {
        fetchRoles();
        fetchLocations();
        fetchData();
    }, [])

    return (
        <>
            {alert}

            <CustomModal
                show={showAddEditModal}
                handleClose={handleCloseAddEditModal}
                title={userId === 0 ? "Add User" : "Edit User"}
                backdrop="static">
                <UserPage
                    userId={userId}
                    user={selectedUser}
                    roles={roles}
                    locations={locations}
                    handleChange={handleChange}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    handleQr={handleQr}
                    handlePin={handlePin}
                    validateInput={validateInput}
                    error={error}
                    handleShowSave={handleShowSave}
                    isEdit={isEdit}
                    handleEdit={handleEdit}
                />
            </CustomModal>

            {getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) &&
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Container style={{ height: 750 }} fluid>

                                    <Row className="elysium-h100">
                                        <UserTable
                                            data={data}
                                            handleAdd={handleAdd}
                                            handleShow={handleShowUser}
                                            handleDelete={handleDelete}
                                        />
                                    </Row>

                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
            {!getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}

export default UserManagement;

import React, { useState, useEffect, useMemo } from "react";
//import Moment from 'react-moment';
// react component used to create charts

// react-bootstrap components
import {
    Card,
    Form,
    Container,
    Row,
    Col,
    Button
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'

import LocationTable from '../../components/Locations/LocationTable';
import CustomTable from '../../components/Shared/CustomTable';
import CustomModal from '../../components/Shared/CustomModal';
import AddEditLocation from "../../components/Locations/AddEditLocation";
import UpdateLocationPriorityModal from "../../components/Locations/UpdateLocationPriorityModal";
import { showSweetAlert } from 'components/Shared/CustomAlert';

import { LOCATION_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';
function Location() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);

    // ### STATE ###
    const [data, setData] = useState([]);
    const [priorityLocations, setPriorityLocations] = useState([]);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState({});
    const newLocations = [...priorityLocations];
    const LocationName = [...data];
    const [DataChanged, setDataChanged] = useState(false);
    const [showLocationPriorityModal, setShowLocationPriorityModal] = useState(false);
    const [isPrioritySort, setIsPrioritySort] = useState(false);
    const handleLocationPriorityViewModelHide = () => setShowLocationPriorityModal(false);
    const showLocationPriorityViewModel = () => setShowLocationPriorityViewModel(true);
    const [validation, setvalidation] = useState({
        name: '',
        code: '',
        email: "",
        contactNumber: "",
        managerEmail: "",
    })
    //DragDrop
    const [location, setLocationState] = useState([]);
    //hooks
    const handleCloseAddEditModal = () => setShowAddEditModal(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (name, value) => {
        setIsChecked(value);
        setSelectedLocation({
            ...selectedLocation,
            [name]: value
        })
    };


    //Style

    const grid = 4;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 5px 0`,
        height: `40px`,

        // change background colour if dragging
        background: isDragging ? "#B4B4B4" : "#B4B4B4",

        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        padding: grid,
        width: 250
    });

    // ### HANDLERS ###
    const handleAdd = () => {
        clearLocationValidation();
        setLocationId(0);
        setSelectedLocation({
            id: 0,
            name: "",
            code: "",
            contactNumber: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            county: "",
            postcode: "",
            email: "",
            managerEmail: "",
            assistantManagerEmail: "",
            ignoreImportExport: false,
            has4by4Printer: false,
            has2by1Printer: false
        });
        setShowAddEditModal(true);
    }

    // handleSaveLocationPriority
    const handleSaveLocationPriority = () => {
        if (isPrioritySort) {
            showSweetAlert('warning', `Are you sure you want to save the location priority?`, () => { handleUpdatePriorities() });
        }
        else {
            showSweetAlert('warning', `Are you sure you want to save the location order?`, () => { handleUpdatePriorities() });
        }
    }
    //handle Cancelpriority
    const handleCancelPriority = () => {
        if (DataChanged) {
            showSweetAlert('warning', `Are you sure you want to cancel these changes?`, () => { cancelPriority() });
        } else {
            handleLocationPriorityViewModelHide()
        }
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const cancelPriority = () => {
        setDataChanged(false);
        fetchLocationPriority();
        handleLocationPriorityViewModelHide();
    }
    //handleDrag
    const handleDragEnd = (result) => {
        if (!result.destination) {
            // If the item is dropped outside the list, return without making any changes
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Create a new array to hold the reordered priorityLocations
        const updatedPriorityLocations = Array.from(priorityLocations);
        const [reorderedItem] = updatedPriorityLocations.splice(sourceIndex, 1);

        // Reorder the item at the destination index
        updatedPriorityLocations.splice(destinationIndex, 0, reorderedItem);

        // Update the state with the new reordered priorityLocations
        setPriorityLocations(updatedPriorityLocations);

        //const { source, destination } = result;
        //// dropped outside the list
        //if (!destination) {
        //    return;
        //}
        //const sInd = +source.droppableId;
        //const dInd = +destination.droppableId;

        //if (sInd === dInd) {
        //    const items = reorder(location[sInd], source.index, destination.index);
        //    const newState = [...location];
        //    newState[sInd] = items;
        //    setLocationState(newState);
        //    newState.length > 0 && newState[0].forEach((updatedLocation, index) => {
        //        priorityLocations.forEach((location, locationIndex) => {
        //            if (location.locationId === updatedLocation.id) {
        //                location.priorityOrder = index + 1;
        //            }
        //        })
        //    });

        //    // } else {
        //    //     const result = move(location[sInd], location[dInd], source, destination);
        //    //     const newState = [...location];
        //    //     newState[sInd] = result[sInd];
        //    //     newState[dInd] = result[dInd];

        //    //     setLocationState(newState.filter(group => group.length));
        //}


        //setDataChanged(true);
    };


    //Get Location Priority
    const fetchLocationPriority = async () => {
        try {

            let url = `/location/GetAllPriorities?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&isPriority=${isPrioritySort}`;
            const response = await fetch(url);
            const Priorities = await response.json();
            setPriorityLocations(Priorities);
        } catch (err) {
        }
    }

    // Update Location Priority 
    const handleUpdatePriorities = async () => {
        try {
            let url = `location/UpdatePriorities?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&isPriority=${isPrioritySort}`;
            const response = await fetch((url), {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(priorityLocations),
            });
            if (response.status == 200) {

                showSweetAlert('saveSuccess', `Location Priorities`);
                handleLocationPriorityViewModelHide();
                setDataChanged(false)
                setData(location.length > 0 && location[0])

            } else {
                showSweetAlert('serverError');
            }
        } catch (e) {
            showSweetAlert('serverError');
        } finally {
            fetchData();
        }
    }
    const handleEdit = async (id) => {
        clearLocationValidation();
        try {
            let url = `/location/getsingle?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const location = await response.json();
            setLocationId(location.id);
            setSelectedLocation(location);
            setShowAddEditModal(true);
            setShowAddEditModal(true);
        } catch (err) {
        }
    }

    const handleChange = (name, value) => {
        setSelectedLocation({
            ...selectedLocation,
            [name]: value
        })
    }

    const handleSave = async () => {
        if (selectedLocation.name === '' || selectedLocation.name === null) {
            validation.name = "Please enter a name.";
        }
        if (selectedLocation.code === '' || selectedLocation.code === null) {
            validation.code = "Please enter a code.";
        }
        if (selectedLocation.contactNumber === '' || selectedLocation.contactNumber === null) {
            validation.contactNumber = "Please enter a contact number.";
        }
        if (selectedLocation.email === '' || selectedLocation.email === null) {
            validation.email = "Please enter an email.";
        }
        if (selectedLocation.managerEmail === '' || selectedLocation.managerEmail === null) {
            validation.managerEmail = "Please enter a manager email.";
        }


        if (selectedLocation.code !== '' || selectedLocation.code !== null && selectedLocation.code.length > 2) {

            validation.code = "Please enter a code of at least 3 characters.";
        }


        if (selectedLocation.name !== '' && selectedLocation.name !== null && selectedLocation.managerEmail !== '' && selectedLocation.managerEmail !== null && selectedLocation.code !== '' && selectedLocation.code !== null && selectedLocation.email !== '' && selectedLocation.email !== null && selectedLocation.contactNumber !== '' && selectedLocation.contactNumber !== null && selectedLocation.code.length > 2) {

            // Add
            if (locationId === 0) {
                try {
                    const response = await fetch(`/location/add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                        method: 'post',
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"]
                        ],
                        body: JSON.stringify(selectedLocation),
                    });
                    const body = await response.json();
                    {/* TODO: Add successful/ error code*/ }

                    if (response.status === 200) {
                        showSweetAlert('saveSuccess');
                    }
                    else {
                        showSweetAlert('serverError');
                    }
                }
                catch (err) {
                }
            }

            // Edit 
            else {
                try {
                    const response = await fetch(`/location/update?id=${locationId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                        method: 'put',
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"]
                        ],
                        body: JSON.stringify(selectedLocation),
                    });
                    const body = await response.json();

                    if (response.status === 200) {
                        showSweetAlert('saveSuccess');
                    }
                    else {
                        showSweetAlert('serverError');
                    }
                } catch (err) {
                }
            }
            setLocationId(0);
            setShowAddEditModal(false);
            await fetchData();

        }
        
    };

    const handleDelete = (id) => {
        showSweetAlert('deleteWarning', `Location ${id}`, () => { successDelete(id) });
    }
    const successDelete = async (id) => {
        try {
            const response = await fetch(`/location/delete?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            if (response.status === 200) {
                showSweetAlert('deleteSuccess', `Location`);
            }
            else {
                showSweetAlert('serverError');
            }
            await fetchData();
        } catch (err) {
        }
    };

    const validateInput = (name, value) => {

        setvalidation(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "name":
                    if (!value) {
                        stateObj[name] = "Please enter a name.";
                    }
                    break;
                case "code":
                    if (!value) {
                        stateObj[name] = "Please enter a code.";
                    }
                    break;
                case "contactNumber":
                    if (!value) {
                        stateObj[name] = "Please enter a contact number.";
                    }
                    break;
                case "email":
                    if (!value) {
                        stateObj[name] = "Please enter an email.";
                    }
                    break;
                case "managerEmail":
                    if (!value) {
                        stateObj[name] = "Please enter a manager email.";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    }
    const clearLocationValidation = () => {
        setvalidation({
            name: '',
            code: '',
            email: "",
            contactNumber: "",
            managerEmail: "",
        })
    };

    const handleAddLocation = () => {
        let message = "Are you sure you want to save the changes to this location?";
        if (data.id === 0) message = "Are you sure you want to add this location?";
        showSweetAlert('warning', message, () => {handleSave()});
    }

    // ### DATA FETCHES ###
    const fetchData = async () => {
        try {
            let url = `/location/getall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`; //todo - move to store
            const response = await fetch(url);
            const locations = await response.json();
            setData(locations);
            setLocationState([locations]);
        } catch (err) {
        }
    }
    const handleLocationPriorityButtonClick = () => {
        setShowLocationPriorityModal(true);
        setIsPrioritySort(true);
    };

    const handleLocationOrderButtonClick = () => {
        setShowLocationPriorityModal(true);
        setIsPrioritySort(false);
    };
    // ### HOOKS ###

    useEffect(() => {
        fetchData();
    }, [])

    useMemo(async () => {
        fetchLocationPriority();
    }, [isPrioritySort])

    return (
        <>
            {alert}
            <CustomModal
                show={showAddEditModal}
                handleClose={handleCloseAddEditModal}
                title={locationId === 0 ? "Add Location" : "Edit Location"}
                backdrop="static">
                <AddEditLocation
                    data={selectedLocation}
                    handleSave={handleSave}
                    handleCancel={handleCloseAddEditModal}
                    handleChange={handleChange}
                    validateInput={validateInput}
                    handleAddLocation={handleAddLocation}
                    validation={validation}
                    handleCheckboxChange={handleCheckboxChange}
                    isChecked={isChecked}
                />
            </CustomModal>
            {showLocationPriorityModal &&
                <CustomModal
                    show={showLocationPriorityModal}
                    handleClose={handleLocationPriorityViewModelHide}
                    title={isPrioritySort ? "Change Location Priority" : "Change Location Sort Order"}
                    backdrop="static">
                    <UpdateLocationPriorityModal
                        data={location}
                        priorityLocations={priorityLocations}
                        handleDragEnd={handleDragEnd}
                        handleSaveLocationPriority={handleSaveLocationPriority}
                        handleCancelPriority={handleCancelPriority}
                        handleLocationPriorityViewModelHide={handleLocationPriorityViewModelHide}
                        showLocationPriorityModal={showLocationPriorityModal}
                        getListStyle={getListStyle}
                        getItemStyle={getItemStyle}
                    />


                </CustomModal>
            }
            {getPermission(user.permissions, LOCATION_VIEW) &&
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Container style={{ height: 750 }} fluid>
                                    <Row className="elysium-h100">
                                        <LocationTable
                                            handleLocationPriorityViewModelHide={handleLocationPriorityViewModelHide}
                                            showLocationPriorityViewModel={showLocationPriorityViewModel}
                                            //  showCategoryModal={showCategoryModal}
                                            title="Locations"
                                            itemType="Location"
                                            data={data}
                                            handleAdd={handleAdd}
                                            handleEdit={handleEdit}
                                            handleDelete={handleDelete}
                                            // fetchLocationPriority={fetchLocationPriority}
                                            // priorityLocations={priorityLocations}
                                            handleUpdatePriorities={handleUpdatePriorities}
                                            handleDragEnd={handleDragEnd}
                                            handleSaveLocationPriority={handleSaveLocationPriority}
                                            handleCancelPriority={handleCancelPriority}
                                            handleLocationPriorityButtonClick={handleLocationPriorityButtonClick}
                                            handleLocationOrderButtonClick={handleLocationOrderButtonClick}
                                        />
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
            {!getPermission(user.permissions, LOCATION_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}

export default Location;

import React from "react";
import { useEffect, useState } from 'react';
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Button,
    Col,
    Row,
    Form
} from "react-bootstrap";

function AddEditLocation(props) {

    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, [])

    const handleSave = () => {
        let message = "Are you sure you want to save the changes to this location?";
        if (props.data.id === 0) message = "Are you sure you want to add this location?";
        showSweetAlert('warning', message, () => { hideSave() });
    }

    const hideSave = () => {        
        props.handleSave()
    }

    const hideAlert = () => {
        
    }

    const handleCancel = () => {
        props.handleCancel();
    }

    return (
        <>
            {alert}
            <Form action="" className="form" method="">
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Location Name</label>

                            <Form.Control
                                placeholder="Location Name"
                                type="text"
                                value={props.data.name}
                                onChange={(e) => props.handleChange("name", e.target.value)}
                                onBlur={(e) => props.validateInput("name", e.target.value)}
                            />
                            {props.validation.name && <label style={{ color: "red" }} className='err'>{props.validation.name}</label>}
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Code</label>

                            <Form.Control
                                placeholder="Code"
                                type="text"
                                value={props.data.code}
                                onChange={(e) => props.handleChange("code", e.target.value)}
                                onBlur={(e) => props.validateInput("code", e.target.value)}
                            />
                            {props.validation.code && <label style={{ color: "red" }} className='err'>{props.validation.code}</label>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Contact Number</label>
                            <Form.Control
                                placeholder="Contact Number"
                                type="text"
                                value={props.data.contactNumber}
                                onChange={(e) => props.handleChange("contactNumber", e.target.value)}
                                onBlur={(e) => props.validateInput("contactNumber", e.target.value)}
                            />
                            {props.validation.contactNumber && <label style={{ color: "red" }} className='err'>{props.validation.contactNumber}</label>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Address Line 1</label>
                            <Form.Control
                                placeholder="Address Line 1"
                                type="text"
                                value={props.data.addressLine1}
                                onChange={(e) => props.handleChange("addressLine1", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Address Line 2</label>
                            <Form.Control
                                placeholder="Address Line 2"
                                type="text"
                                value={props.data.addressLine2}
                                onChange={(e) => props.handleChange("addressLine2", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>City</label>
                            <Form.Control
                                placeholder="City"
                                type="text"
                                value={props.data.city}
                                onChange={(e) => props.handleChange("city", e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>County</label>

                            <Form.Control
                                placeholder="County"
                                type="text"
                                value={props.data.county}
                                onChange={(e) => props.handleChange("county", e.target.value)}
                            />

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Postcode</label>

                            <Form.Control
                                placeholder="Postcode"
                                type="text"
                                value={props.data.postcode}
                                onChange={(e) => props.handleChange("postcode", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Email</label>

                            <Form.Control
                                placeholder="Email"
                                type="text"
                                value={props.data.email}
                                onChange={(e) => props.handleChange("email", e.target.value)}
                                onBlur={(e) => props.validateInput("email", e.target.value)}
                            />
                            {props.validation.email && <label style={{ color: "red" }} className='err'>{props.validation.email}</label>}
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Manager Email</label>

                            <Form.Control
                                placeholder="Manager Email"
                                type="text"
                                value={props.data.managerEmail}
                                onChange={(e) => props.handleChange("managerEmail", e.target.value)}
                                onBlur={(e) => props.validateInput("managerEmail", e.target.value)}
                            />
                            {props.validation.managerEmail && <label style={{ color: "red" }} className='err'>{props.validation.managerEmail}</label>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Assistant Manager Email</label>

                            <Form.Control
                                placeholder="Assistant Manager Email"
                                type="text"
                                value={props.data.assistantManagerEmail}
                                onChange={(e) => props.handleChange("assistantManagerEmail", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Shopify Id</label>

                            <Form.Control
                                placeholder="Shopify Id"
                                type="number"
                                value={props.data.shopifyId}
                                onChange={(e) => props.handleChange("shopifyId", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>

                            <label>
                                <input
                                    type="checkbox"
                                    checked={props.data.ignoreImportExport}
                                    onChange={(e) => props.handleCheckboxChange("ignoreImportExport", e.target.checked)}
                                />
                                <div className="LocationExport">Exclude From Export</div>
                            </label>
                        </Form.Group>

                    </Col>
                    <Col className="pr-1" md="6">
                        <Form.Group>

                            <label>
                                <input
                                    type="checkbox"
                                    checked={props.data.has4by4Printer}
                                    onChange={(e) => props.handleCheckboxChange("has4by4Printer", e.target.checked)}
                                />
                                <div className="Has4by4Printer">Has 4 x 4 Printer</div>
                            </label>
                        </Form.Group>

                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>

                            <label>
                                <input
                                    type="checkbox"
                                    checked={props.data.has2by1Printer}
                                    onChange={(e) => props.handleCheckboxChange("has2by1Printer", e.target.checked)}
                                />
                                <div className="Has2by1Printer">Has 2.3x1.3 Printer</div>
                            </label>
                        </Form.Group>

                    </Col>
                </Row>

                <Button
                    className="btn-fill float-end"
                    variant="primary"
                    onClick={props.handleAddLocation}
                >
                    Save
                </Button>

                <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>

                <div className="clearfix"></div>
            </Form >
        </>
    );
}
export default AddEditLocation;
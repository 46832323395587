import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    Button,
    Form,
    ListGroup,
    ListGroupItem
} from "react-bootstrap";

function UpdateLocationPriorityModal(props) {

    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '250px');
    }, [])

    // Step 1: Create a state variable to store the reordered priorityLocations
    const [reorderedPriorityLocations, setReorderedPriorityLocations] = useState(
        props.priorityLocations
    );
    
    // Step 2: Implement the onDragEnd function to update the state with the new order
    const onDragEnd = (result) => {
        if (!result.destination) {
            // If the item is dropped outside the list, return without making any changes
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Create a new array to hold the reordered priorityLocations
        const updatedPriorityLocations = Array.from(reorderedPriorityLocations);
        const [reorderedItem] = updatedPriorityLocations.splice(sourceIndex, 1);

        // Reorder the item at the destination index
        updatedPriorityLocations.splice(destinationIndex, 0, reorderedItem);

        // Update the state with the new reordered priorityLocations
        setReorderedPriorityLocations(updatedPriorityLocations);
    };

    return (
        <div className="location_list_view">
            <div className="d-flex">
                <>
                    <DragDropContext onDragEnd={props.handleDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={props.getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    {/* Step 3: Use reorderedPriorityLocations instead of props.priorityLocations */}
                                    {props.priorityLocations.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    className="d-flex align-items-center"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={props.getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div className="item_name">{item.name}</div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            </div>
            <>
                <div className="buttonStyle d-flex justify-content-end">
                    <Button
                        className="btn-fill"
                        variant="info"
                        onClick={props.handleCancelPriority}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-fill"
                        variant="primary"
                        onClick={props.handleSaveLocationPriority}
                    >
                        Save
                    </Button>
                </div>
            </>
        </div>
    );
}
export default UpdateLocationPriorityModal;

import React, { memo } from 'react'
import { useDrag } from 'react-dnd'
import {
    Button,
    Form,
    Row,
    Col,
    Card
} from "react-bootstrap";

import BuyersCollectionImageGallery from '../Buyers/BuyersCollectionImageGallery'
import CustomSelect from '../Shared/CustomSelect';

export const Box = memo(function Box(props) {
    const { name, productData, type, key } = props;

    const [{ opacity }, drag] = useDrag(
        () => ({
            type,
            item: { id: productData.id },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [name, type],
    )
    return (
        <>
            <Card ref={drag} className={props.showDetails ? `buyers-${props.itemCardColumns}-wide` : `buyers-${props.itemCardColumns}-wide no-details`} style={{ opacity }} data-testid="box">
                <div className="logoImage">
                    <div className="custom-image-gallery-item">
                        {productData.images?.length > 0 ?
                            <BuyersCollectionImageGallery items={productData.images} /> :
                            <img height={80} alt="..." className="border-gray" src={require("assets/img/no-image.jpg").default} />
                        }
                    </div>
                </div>
                <Button
                    className="btn-link btn-xs position-absolute top-0 end-0"
                    variant="success"
                    onClick={() => props.handleEditBuyersCollectionProductDetailModal(productData)}
                    style={{ width: "15px", top: "5px", right: "5px" }} // Set the width and position
                >
                    <i className="fas fa-edit"></i>
                </Button>
                <Button
                    className="btn-link btn-xs position-absolute"
                    variant="success"
                    onClick={() => props.handleDeleteBuyersCollectionProduct(productData.id)}
                    style={{ width: "15px", top: "25px", right: "2px" }} // Set the width and position
                >
                    <i className="fas fa-times"></i>
                </Button>
                <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: "1" }}>
                        <Row>
                            <Col className={`buyers-${props.itemCardColumns}-wide-text`}>
                                <label><b>Name</b>:&nbsp;
                                    {productData.productId != null ?
                                        <a href="#id" onClick={() => props.handleGetItemCard(productData.productId)}>{productData.name}</a>
                                        :
                                        productData.name
                                    }
                                </label>
                                {props.showDetails === true &&
                                    <>
                                        <label className="card-name"><b>Brand Code</b>: {productData.brandCode}</label>
                                        <label className="card-name"><b>Cost</b>: {productData.cost}</label>
                                        <label className="card-name"><b>RRP</b>: {productData.rrp}</label>
                                        <label className="card-name"><b>Colour</b>: {productData.colour}</label>
                                        <label className="card-name"><b>Locations</b>: {productData.totalLocations}</label>
                                        <label className="card-name"><b>Allocated</b>: {productData.totalAllocated}</label>
                                    </>
                                }
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <CustomSelect
                                value={String(productData.approved)}
                                placeholder="Approved"
                                data={[
                                    { id: "true", value: "Approved" },
                                    { id: "false", value: "Unapproved" },
                                ]}
                                handleChange={(e) => props.handleApprovalChange(productData.id, e.value)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
})

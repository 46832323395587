import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import CustomSelect from '../../components/Shared/CustomSelect';
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container, Form
    , Dropdown
} from "react-bootstrap";

import CustomSearchBox from '../../components/Shared/CustomSearchBox';
import { showSweetAlert } from 'components/Shared/CustomAlert';
import { ADMIN_USERMANAGEMENT_EDIT, ADMIN_USERMANAGEMENT_DELETE } from '../../constants/Permissions.js';
import { getPermission } from '../../utils/Permissions.js';

function CategoryTable(props) {

    const user = useSelector((state) => state.user);

    

    const handlePermission = () => {
        showSweetAlert('unauthorized');
    }

    const handleDefaultCategory = () => {
        showSweetAlert('systemCategory');
    }

    const renderTableData = () => {
        return props.data.map((category, index) => {
            const { id, name, isSystemCategory } = category
            return (
                <tr
                    key={`CategoryManagement${index}${id}`}
                    className={props.categoryId === id ? "table-selected-row cursor-pointer" : "cursor-pointer"}
                >
                    <td onClick={() => props.handleSelectCategory(category)}>{name}</td>
                    <td className="td-actions">
                        {isSystemCategory ?
                            <>
                                <OverlayTrigger
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip id="tooltip-981231696">
                                            Edit
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        onClick={() => handleDefaultCategory()}
                                        className="btn-link btn-xs"
                                        variant="success"
                                        
                                    >
                                        <i className="fas fa-edit"></i>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip id="tooltip-255158527">
                                            Remove
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        className="btn-link btn-xs"
                                        variant="danger"
                                        onClick={() => handleDefaultCategory()}
                                        
                                    >
                                        <i className="fas fa-times" ></i>
                                    </Button>
                                </OverlayTrigger>
                            </>
                            : <>
                                <Button
                                    onClick={() => props.handleEdit(category)}
                                    className="btn-link btn-xs"
                                    variant="success"
                                >
                                    <i className="fas fa-edit"></i>
                                </Button>

                                <Button
                                    className="btn-link btn-xs"
                                    variant="danger"
                                    onClick={() => props.handleDelete(id, name)}
                                >
                                    <i className="fas fa-times" ></i>
                                </Button>
                            </>}
                    </td>
                </tr>
            )
        })
    }

    return (
        <>
            {alert}
            <Card className="table-with-links elysium-h100">
                <Card.Header>
                    <Card.Title as="h4">
                        <div>Categories</div>
                    </Card.Title>
                    <div className="d-flex justify-content-between">
                        <div className="w-100">
                            <CustomSearchBox
                                placeholder="Search"
                                value={props.search}
                                handleChange={(e) => props.handleSearchChange(e)}
                                handleClear={() => props.handleSearchChange("")}
                            />
                        </div>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-255158527">
                                    Add
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handleAdd()}
                            >
                                Add
                            </Button>
                        </OverlayTrigger>
                    </div>
                    <p className="card-category">
                    </p>
                </Card.Header>
                <Card.Body className="table-full-width elysium-h70">
                    <Col className="overflow-auto elysium-h100">
                        <Table>
                            <thead>
                                <tr>
                                    <th width="80%">Category Name</th>
                                    <th width="20%" className="td-actions text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
}

export default CategoryTable;


import React, { useState, useEffect, useMemo } from "react";
import AutoReplenishmentTable from "components/AutoReplenishment/AutoReplenishmentTable";
function AutoReplenishment(){

     // ### STATE ###
     const [autoReplenishment, setAutoReplenishment] = useState([]);
     const [isLoading, setIsLoading] = useState(true);
     // ### DATA FETCHES ###
     const fetchAutoReplenishment = async () => {
        setIsLoading(true);
        try {
            let url = `Movement/GetAutoReplenishmentList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`; //todo - move to store
            const response = await fetch(url);
            const autoReplenishment = await response.json();
            
            setAutoReplenishment(autoReplenishment);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        fetchAutoReplenishment();

    }, [])
return(
    <AutoReplenishmentTable
     data={autoReplenishment}
     isLoading={isLoading}
    />

);
}
export default AutoReplenishment;
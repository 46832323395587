import React, { useEffect } from "react"
import CustomTable from "components/Shared/CustomTable";
import MovementsProductTable from "./MovementsProductTable";
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner, Form
} from "react-bootstrap";
function MovementsModal(props) {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '1200px');
    }, [])

    var hasPermission = false;
    var permissions = localStorage.getItem("permissions");

    if (permissions.includes("Movements.Edit"))
    {
        hasPermission = true;
    }

    return (
        <>
            {alert}
            <Container fluid className="PurchaseModal">

                <Col md="12">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md="3">
                                    <Form.Group>
                                        <label>Batch Number</label>
                                        <Form.Control

                                            placeholder="Batch Number"
                                            type="text"
                                            value={props.SingleMovement.batchName}
                                            disabled
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group>
                                        <label>Location From</label>
                                        <Form.Control

                                            placeholder="Location From"
                                            type="text"
                                            value={props.SingleMovement.fromLocationName}
                                            disabled
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group>
                                        <label>Location To</label>
                                        <Form.Control

                                            placeholder="Location To"
                                            type="text"
                                            value={props.SingleMovement.toLocationName}
                                            disabled
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group>
                                        <label>Type Name</label>
                                        <Form.Control

                                            placeholder="Type Name"
                                            type="text"
                                            value={props.SingleMovement.typeName}
                                            disabled
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Container>

            <Container fluid className="PurchaseModalTable">
                <Row>
                    <Col md="12">
                        <MovementsProductTable
                            SingleMovement={props.SingleMovement}
                            MovementsProductData={props.MovementsProductData}
                            isLodaing={props.isLodaing}
                            handleSearchChange={props.handleSearchChange}
                            search={props.search}
                            handleAddMovementProduct={props.handleAddMovementProduct}
                            handleGetItemCard={props.handleGetItemCard}
                        />

                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default MovementsModal
import React, { useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";

function AddEditBuyersCollection(props) {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '650px');
        document.documentElement.style.setProperty('--modal-width-tablet', '50%');
    }, [])

    return (
        <>
            <Form action="" className="form" method="">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Collection Name</label>
                                    <Form.Control
                                        value={props.buyersCollection.name}
                                        placeholder="Collection Name"
                                        type="text"
                                        onChange={(e) => props.handleDropDownChange("name", e.target.value)}
                                        onBlur={(e) => props.validateInput("name", e.target.value)}
                                    />

                                    {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>}

                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Brand</label>
                                    <CustomSelect
                                        value={props.buyersCollection.brandId}
                                        placeholder="Brand"
                                        data={props.brand}
                                        handleChange={(e) => props.handleDropDownChange("brandId", e.value)}
                                    />
                                    {props.error.brandId && <label style={{ color: "red" }} className='err'>{props.error.brandId}</label>}
                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Season</label>
                                    <CustomSelect
                                        value={props.buyersCollection.seasonId}
                                        placeholder="Season"
                                        data={props.season}
                                        handleChange={(e) => props.handleDropDownChange("seasonId", e.value)}
                                    />
                                    {props.error.seasonId && <label style={{ color: "red" }} className='err'>{props.error.seasonId}</label>}
                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Gender</label>
                                    <CustomSelect
                                        value={props.buyersCollection.genderId}
                                        placeholder="Gender"
                                        data={props.gender}
                                        handleChange={(e) => props.handleDropDownChange("genderId", e.value)}
                                    />
                                    {props.error.genderId && <label style={{ color: "red" }} className='err'>{props.error.genderId}</label>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            props.isEdit ?
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={props.handleUpdateBuyersCollection}
                                >
                                    Update
                                </Button>
                                :
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={props.handleAddBuyersCollection}
                                >
                                    Save
                                </Button>
                        }
                        <Button
                            className="btn-fill float-end"
                            variant="info"
                            onClick={props.handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <div className="clearfix"></div>
                    </Card.Body>
                </Card>
            </Form >
        </>
    )
}
export default AddEditBuyersCollection;
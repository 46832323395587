import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import Select from "react-select";
import {
    Card,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { useSelector } from 'react-redux'

import CustomSelect from "components/Shared/CustomSelect";
import CategoryTable from '../../components/Category/CategoryTable';
import CategoryGroups from '../../components/Category/CategoryGroups';
import CategoryValues from '../../components/Category/CategoryValues';

import { CATEGORIES_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

//Modals
import { showSweetAlert } from 'components/Shared/CustomAlert';
import CustomModal from '../../components/Shared/CustomModal';
import AddEditCategoryModal from '../../components/Category/AddEditCategoryModal';
import AddEditCategoryGroupModal from '../../components/Category/AddEditCategoryGroupModal';
import AddEditCategoryValueModal from '../../components/Category/AddEditCategoryValueModal';

function Category() {

    // ### STATE ###

    const user = useSelector((state) => state.user);

    const [showCategoryDetails, setShowCategoryDetails] = useState(false); // right hand pane

    const [showCategoryModal, setShowCategoryModal] = useState(false); // modals
    const [showCategoryGroupModal, setShowCategoryGroupModal] = useState(false);
    const [showCategoryValueModal, setShowCategoryValueModal] = useState(false);

    const [searchCategories, setSearchCategories] = useState("");

    const [categoryId, setCategoryId] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const [categoryGroupId, setCategoryGroupId] = useState(0);
    const [selectedCategoryGroup, setSelectedCategoryGroup] = useState([]);

    const [originCategories, setOriginCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryGroups, setCategoryGroups] = useState([]);
    const [categoryValues, setCategoryValues] = useState([]);
    const [selectedCategoryValues, setSelectedCategoryValues] = useState([]);

    const [categoryValueId, setCategoryValueId] = useState(0);
    const [selectedCategoryValue, setSelectedCategoryValue] = useState([]);
    const [suppliers, setSupplier] = useState([]);
    
    const [catrgorySize, setCategorySize] = useState([]);

    //const [nameError, SetnameError] = useState(false);

    // ### HANDLERS ###

    const handleCategoryDetailsShow = () => setShowCategoryDetails(true);
    const handleCategoryDetailsHide = () => setShowCategoryDetails(false);

    const handleCategoryModalShow = () => setShowCategoryModal(true);
    const handleCategoryModalHide = () => setShowCategoryModal(false);

    const handleCategoryGroupModalShow = () => setShowCategoryGroupModal(true);
    const handleCategoryGroupModalHide = () => setShowCategoryGroupModal(false);

    const handleCategoryValueModalShow = () => setShowCategoryValueModal(true);
    const handleCategoryValueModalHide = () => setShowCategoryValueModal(false);

    const handleCategorySearchChange = (value) => {
        const filterData = originCategories?.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
        setSearchCategories(value);
        setCategories(filterData);
    }

    //const handleValidateInput = (name, value) => {
    //    switch (name) {
    //        case "name":
    //            if (!value) {
    //                SetnameError(true)
    //            }
    //    }
    //}

    const [error, setError] = useState({
        name: '',
        code: '',
        supplierId: '',
        sizeGroupId: ''
    })
    const clearCategoryValidation = () => {
        setError({
            name: '',
            code: '',
            supplierId: '',
            sizeGroupId: ''
        })
    };
    // --- Category ---
    const handleAddCategory = () => {
        setCategoryId(0);
        setSelectedCategory({
            id: 0,
            name: "",
            valueType: "",
            categoryGroupIds: [],
            dateCreated: "0001-01-01T00:00:00",
            dateAmended: "0001-01-01T00:00:00",
            archived: false
        });
        handleCategoryModalShow();
    }

    const handleEditCategory = (category) => {
        clearCategoryValidation();
        setCategoryId(category.id);
        setSelectedCategory(category);
        setShowCategoryModal(true)
    }

    const handleChangeCategory = (name, value) => {
        let item = {
            ...selectedCategory,
            [name]: value,
        };
        setSelectedCategory(item);
    }

    const handleGetSupplier = async () => {

        try {
            let url = `/supplier/GetDropdownList?userId=${localStorage.getItem("userId")}&&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            const Data = body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setSupplier(Data);
        } catch (e) {

        }

    }

    const handleSelectCategory = async (category) => {
        setCategoryId(category.id);
        setSelectedCategory({ ...category });
        setSelectedCategoryGroup(null);
        setSelectedCategoryValues([]);
        await fetchCategoryGroups(category.id);
        await fetchCategoryValues(category.id);
        setShowCategoryDetails(true);
        fetchCategorSize();
    }

    const handleDeleteCategory = (id, name) => {
        showSweetAlert('deleteWarning', `category`, () => { deleteCategoryHandler(id) });
    }

    const deleteCategoryHandler = async (index) => {
        try {
            await fetch(`/Category/Delete?id=${index}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            showSweetAlert('deleteSuccess', `Category`);
            await fetchCategories();
            setShowCategoryDetails(false);
        } catch (err) {
        } finally {
            await fetchCategories();
        }
    };

    const saveCategoryHandler = async () => {
        if (selectedCategory.name == "") {
            //SetnameError(true)
            //SetCode(true)
            return
        }

        // Add Category
        if (categoryId === 0) {
            try {
                let url = `Category/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'post',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(selectedCategory),
                });
                const body = await response.json();
                if (response.status == 200) {
                    showSweetAlert('saveSuccess', `Category`);
                    await fetchCategories();
                    setShowCategoryModal(false);
                } else {
                    showSweetAlert('serverError');
                    await fetchCategories();
                    setShowCategoryModal(false);
                }
            } catch (err) {
            }
        }
        // Edit Category
        else {
            try {
                let url = `Category/Update?id=${selectedCategory.id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'put',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(selectedCategory),
                });
                const body = await response.json();
                if (response.status === 200) {
                    showSweetAlert('saveSuccess', `Category`);
                    await fetchCategories();
                    setShowCategoryModal(false);
                } else {
                    showSweetAlert('serverError');
                    await fetchCategories();
                    setShowCategoryModal(false);
                }
            } catch (err) {

            }
        }
        await fetchCategoryGroups(selectedCategory.id);
    }

    // --- Category End ---

    // --- Category Group ---
    const handleAddCategoryGroup = () => {
        setCategoryGroupId(0);
        setSelectedCategoryGroup({
            id: 0,
            name: "",
            categoryId: selectedCategory.id,
            dateCreated: "0001-01-01T00:00:00",
            dateAmended: "0001-01-01T00:00:00",
            archived: false
        });
        handleCategoryGroupModalShow();
    }

    const handleEditCategoryGroup = () => {
        setCategoryGroupId(selectedCategoryGroup.id);
        setSelectedCategoryGroup({ ...selectedCategoryGroup });
        setShowCategoryGroupModal(true)
    }

    const handleChangeCategoryGroup = (name, value) => {
        let item = {
            ...selectedCategoryGroup,
            [name]: value,
        };
        setSelectedCategoryGroup(item);
    }

    const handleSelectCategoryGroup = (id) => {
        setCategoryGroupId(id);
        //filter groups by id
        let category = categoryGroups.filter(x => x.id == id);
        setSelectedCategoryGroup(...category);

        // filter category values 
        if (id != 0) {
            let values = categoryValues.filter(x => x.categoryGroupId == id);
            setSelectedCategoryValues(values);
        }
        else {
            setSelectedCategoryValues(categoryValues);
        }
    }

    const handleDeleteCategoryGroup = (id, name) => {
        showSweetAlert('deleteWarning', `category group`, () => { deleteCategoryGroupHandler(id) });
    }

    const deleteCategoryGroupHandler = async (index) => {
        try {
            // todo: check that no values are attached to the group before deleting
            await fetch(`/CategoryGroup/Delete?id=${selectedCategoryGroup.id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            showSweetAlert('deleteSuccess', `Category Group`);
            await fetchCategoryGroups();
            setCategoryGroupId(0);
            // refresh category values
        } catch (err) {
        } finally {
            await fetchCategories();
            await fetchCategoryGroups(selectedCategory.id);
        }
    };

    const saveCategoryGroupHandler = async () => {

        if (selectedCategoryGroup.name == "") {
            //SetnameError(true)
            //SetCode(true)
            return
        }

        // Add Category
        if (categoryGroupId === 0) {
            try {
                let url = `CategoryGroup/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'post',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(selectedCategoryGroup),
                });
                const body = await response.json();
                if (response.status == 200) {
                    showSweetAlert('saveSuccess', `Category Group`);
                    await fetchCategoryGroups();
                    setShowCategoryGroupModal(false);
                } else {
                    showSweetAlert('serverError');
                    await fetchCategoryGroups();
                    setShowCategoryGroupModal(false);
                }
            } catch (err) {
            }
        }
        // Edit Category
        else {
            try {
                let url = `CategoryGroup/Update?id=${selectedCategoryGroup.id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'put',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(selectedCategoryGroup),
                });
                const body = await response.json();
                if (response.status === 200) {
                    showSweetAlert('saveSuccess', `Category Group`);
                    await fetchCategoryGroups();
                    setShowCategoryGroupModal(false);
                } else {
                    showSweetAlert('serverError');
                    await fetchCategoryGroups();
                    setShowCategoryGroupModal(false);
                }
            } catch (err) {

            }
        }
        await fetchCategories();
        await fetchCategoryGroups(selectedCategory.id);
    }

    // --- Category Group End ---

    // --- Category Values ---
    const handleAddCategoryValue = () => {

        setError({})
        setCategoryValueId(0);
        setSelectedCategoryValue({
            id: 0,
            name: "",
            code: "",
            value: "",
            requiresSupplierId: true,
            categoryId: categoryId,
            categoryGroupId: categoryGroupId,
            supplierId: 0,
            supplierName: "",
            requiresSizeGroupId: true,
            sizeGroupId: 0,
            sizeGroupName: "",
            categoryGroupName:""
        });
        handleCategoryValueModalShow();
    }

    const handleEditCategoryValue = (id) => {
        clearCategoryValidation();
        setCategoryValueId(id);
        let val = categoryValues.filter(x => x.id == id);
        let categoryValue = val[0];

        if (categoryValue.supplierName === null) {
            categoryValue.supplierName = "";
        }
        if (categoryValue.sizeGroupName === null) {
            categoryValue.sizeGroupName = "";
        }


        setSelectedCategoryValue({ ...categoryValue });
        setShowCategoryValueModal(true)
    }
    const handleChangeCategoryDropdown = (name, value, label, labelName) => {
        let item = {
            ...selectedCategoryValue,
            [name]: value,
            [label]: labelName
        };
        setSelectedCategoryValue(item);
        validateInput(name, value)
    }
    const handleChangeCategoryValue = (name, value) => {

        let item = {
            ...selectedCategoryValue,
            [name]: value,
        };
        setSelectedCategoryValue(item);
        validateInput(name, value)
    }
    const validateInput = (name, value) => {

        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "name":
                    if (!value) {
                        stateObj[name] = "Please enter a name";
                    }
                    break;
                case "code":
                    if (!value) {
                        stateObj[name] = "Please enter an code";
                    }
                    break;
                case "supplierId":
                    if (!value) {
                        stateObj[name] = "Please select the supplier";
                    }
                    break;
                case "supplierId":
                    if (!value) {
                        stateObj[name] = "Please select the Size";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    }


    const handleDeleteCategoryValue = (id, name) => {
        showSweetAlert('deleteWarning', `category value`, () => { deleteCategoryValueHandler(id) });
    }

    const deleteCategoryValueHandler = async (index) => {
        try {
            // todo: check that no values are attached to the group before deleting
            const response = await fetch(`/CategoryValue/Delete?id=${index}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });

            if (response.status == 200) {
                showSweetAlert('deleteSuccess', `Category Value`);
                await fetchCategoryValues(categoryId);
            }
            else {
                var body = await response.json();
                showSweetAlert('warningOkOnly', body);
            }
        } catch (err) {
        } finally {
            await fetchCategories();
            await fetchCategoryGroups(selectedCategory.id);
        }
    };
    const saveCategoryValueHandler = () => {
        showSweetAlert('warning', `Are you sure you want to add this category value?`, () => { handleSave() });
    }
    const handleSave = async () => {
        
        if (selectedCategoryValue.name === "") {
            error.name = "Please enter a name"
        }

        if (categoryValues.some(item => item.name.toLowerCase() === selectedCategoryValue.name.toLowerCase() && item.id !== categoryValueId)) {
            error.name = "Please enter a unique name"
        }

        if (selectedCategoryValue.code === "") {
            error.code = "Please enter a code"
        }

        if (categoryValues.some(item => item.code.toLowerCase() === selectedCategoryValue.code.toLowerCase() && item.id !== categoryValueId)) {
            error.code = "Please enter a unique code"
        }

        // This whole error coding needs moving to a better method
        if (selectedCategory.id == 14) {
            error.code = ""
        }

        if (selectedCategoryValue.supplierId === 0 && categoryId === 2) {
            error.supplierId = "Please enter the supplier"
        } else { error.supplierId = "" }

        if (selectedCategoryValue.sizeGroupId === 0 && categoryId === 3) {
            error.sizeGroupId = "Please enter the size"
        } else { error.sizeGroupId = "" }

        // Add Category value
        if (categoryValueId === 0 && error.code === "" && error.supplierId === "" && error.name === "" && error.sizeGroupId === "") {
            try {
                let url = `CategoryValue/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'post',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(selectedCategoryValue),
                });
                const body = await response.json();
                if (response.status == 200) {
                    showSweetAlert('saveSuccess', `Category Value`);
                    await fetchCategoryValues(categoryId);
                    setShowCategoryValueModal(false);
                } else {
                    showSweetAlert('serverError');
                    await fetchCategoryValues(categoryId);
                    setShowCategoryValueModal(false);
                }
            } catch (err) {
            }
        }
        // Edit Category
        else if (categoryValueId !== 0 && error.code === "" && error.supplierId === "" && error.name === "" && error.sizeGroupId === "") {
            try {
                let url = `CategoryValue/Update?id=${selectedCategoryValue.id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'put',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(selectedCategoryValue),
                });
                const body = await response.json();

                if (body == false || body.status === 400) {
                    showSweetAlert('serverError');
                    await fetchCategoryValues(categoryId);
                    setShowCategoryValueModal(false);


                } else {
                    showSweetAlert('saveSuccess', `Category Value`);
                    await fetchCategoryValues(categoryId);
                    setShowCategoryValueModal(false);
                }
            } catch (err) {

            }
        }
        await fetchCategories();
        await fetchCategoryGroups(selectedCategory.id);
    }

    // --- Category Values End ---

    const handlePermission = () => {
        showSweetAlert('unauthorized');
    }

    const hideAlert = () => {
        
    };

    // ### DATA FETCHES ##

    const fetchCategories = async () => {

        try {
            let url = `Category/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            setOriginCategories(body);
            setCategories(body);
        } catch (err) {
        }
    }

    const fetchCategoryGroups = async (id) => {

        try {
            let url = `CategoryGroup/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return {
                    ...item,
                    value: item.name,
                }
            });
            setCategoryGroups(data);
        } catch (err) {
        }
    }


    const fetchCategorSize = async () => {

        try {
            let url = `CategoryGroup/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=${6}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return {
                    ...item,
                    value: item.name,
                }
            });
            setCategorySize(data);
        } catch (err) {
        }
    }

    const fetchCategoryValues = async (id) => {

        try {
            let url = `CategoryValue/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=${id}`;
            const response = await fetch(url);
            const body = await response.json();
     
            setCategoryValues(body);
            setSelectedCategoryValues(body);
        } catch (err) {
        }
    }

    // ### HOOKS ###

    useEffect(() => {
        fetchCategories();
        handleGetSupplier();
        fetchCategoryGroups(categoryId);
    }, []);

    useEffect(() => {
        fetchCategoryGroups(selectedCategory.id);
    }, [selectedCategory]);


    // ### RENDER ###

    return (
        <>
            {alert}

            {/* Add Edit Category Modal*/}
            <CustomModal
                show={showCategoryModal}
                handleClose={handleCategoryModalHide}
                title={categoryId === 0 ? "Add Category" : "Edit Category"}
                backdrop="static">
                <AddEditCategoryModal
                    data={selectedCategory}
                    handleChange={handleChangeCategory}
                    handleSave={saveCategoryHandler}
                    handleCancel={handleCategoryModalHide}
                />
            </CustomModal>

            {/* Add Edit Category Group Modal*/}
            <CustomModal
                show={showCategoryGroupModal}
                handleClose={handleCategoryGroupModalHide}
                title={categoryGroupId === 0 ? "Add Category Group" : "Edit Category Group"}
                backdrop="static">
                <AddEditCategoryGroupModal
                    data={selectedCategoryGroup}
                    handleChange={handleChangeCategoryGroup}
                    handleSave={saveCategoryGroupHandler}
                    handleCancel={handleCategoryGroupModalHide}
                />
            </CustomModal>

            {/* Add Edit Category Value Modal*/}
            <CustomModal
                show={showCategoryValueModal}
                handleClose={handleCategoryValueModalHide}
                title={categoryValueId === 0 ? "Add Category Value" : "Edit Category Value"}
                backdrop="static">
                <AddEditCategoryValueModal
                    data={selectedCategoryValue}
                    handleChange={handleChangeCategoryValue}
                    handleSave={saveCategoryValueHandler}
                    handleCancel={handleCategoryValueModalHide}
                    categories={categories}
                    suppliers={suppliers}
                    categoryId={categoryId}
                    categoryGroups={categoryGroups}
                    catrgorySize={catrgorySize}
                    error={error}
                    handleChangeCategoryDropdown={handleChangeCategoryDropdown}
                />
            </CustomModal>

            {getPermission(user.permissions, CATEGORIES_VIEW) &&
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="4">
                                <Container style={{ height: 750 }} fluid>
                                    <CategoryTable
                                        categoryId={categoryId}
                                        data={categories}
                                        search={searchCategories}
                                        handleSearchChange={handleCategorySearchChange}
                                        handleSelectCategory={handleSelectCategory}
                                        handleAdd={handleAddCategory}
                                        handleEdit={handleEditCategory}
                                        handleDelete={handleDeleteCategory}
                                    />
                                </Container>
                            </Col>
                            <Col md="8">
                                <Container style={{ height: 750 }} fluid>
                                    {showCategoryDetails ?
                                        <Row className="elysium-h100">
                                            <Col md="12" className="elysium-h100">
                                                <Card className="elysium-h100">
                                                    <Card.Header>
                                                        <Card.Header>
                                                            <Card.Title as="h4">{selectedCategory.name}</Card.Title>
                                                        </Card.Header>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <>
                                                            <CategoryGroups
                                                                data={categoryGroups}
                                                                selected={selectedCategoryGroup}
                                                                handleSelect={handleSelectCategoryGroup}
                                                                handleAdd={handleAddCategoryGroup}
                                                                handleEdit={handleEditCategoryGroup}
                                                                handleDelete={handleDeleteCategoryGroup}
                                                            />
                                                            <CategoryValues
                                                                data={selectedCategoryValues}
                                                                requiresSupplierId={selectedCategory.requiresSupplierId}
                                                                requiresSizeGroupId={selectedCategory.requiresSizeGroupId}
                                                                handleAdd={handleAddCategoryValue}
                                                                handleEdit={handleEditCategoryValue}
                                                                handleDelete={handleDeleteCategoryValue}

                                                            />
                                                        </>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        :
                                        <Row className="elysium-h100">
                                            <Col md="12" className="elysium-h100">
                                                <Card className="elysium-h100">
                                                    <Card.Body>
                                                        Select category to show the category details.
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
            {!getPermission(user.permissions, CATEGORIES_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}

export default Category;

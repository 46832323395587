import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Col
} from "react-bootstrap";

function ResetPasswordPage() {
    const [cardClasses, setCardClasses] = useState("card-hidden");
    const [email, setEmail] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    const onResetPassword = async () => {
        try {
            let url = `/account/forgotpassword?email=${email}`;
            const response = await fetch(url, {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
            });
            const isResetPassword = await response.json();

            if (isResetPassword == true) {
                setPasswordReset(true);
            }
        } catch (err) {
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            onResetPassword();
            e.preventDefault();
        }
    }

    useEffect(() => {
        setTimeout(function () {
            setCardClasses("");
        }, 1000);
    });

    return (
        <>
            <div
                className="full-page section-image"
                data-color="dark"
                style={{
                    backgroundImage: "url(" + require("assets/img/background.jpg").default + ")"
                }}
            >
                <div className="content d-flex align-items-center p-0">
                    <Container>
                        {!passwordReset ?
                            <Col className="mx-auto" lg="4" md="8">
                                <Form action="" className="form" method="">
                                    <Card className={"card-login " + cardClasses}>
                                        <Card.Header>
                                            <div>
                                                <Link to="/auth/login">
                                                    <img
                                                        style={{ width: 10 }}
                                                        className="ms-2 mt-1"
                                                        src={require("assets/svg/angle-left-thin.svg").default}
                                                        alt="aldrich-logo"
                                                    />
                                                </Link>
                                                <div className="login-img text-center pt-2">
                                                    <img
                                                        src={require("assets/img/logo.png").default}
                                                        alt="aldrich-logo"
                                                    />
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Body>
                                                <p>Please enter your email address below to be sent instructions to reset your password.</p>
                                                <Form.Group>
                                                    <label>Email</label>
                                                    <Form.Control
                                                        placeholder="Email"
                                                        type="text"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        onKeyDown={(e) => onKeyDown(e)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card.Body>
                                        <Card.Footer className="ml-auto mr-auto text-center">
                                            <Button
                                                className="btn-wd"
                                                variant="primary"
                                                onClick={() => onResetPassword()}
                                            >
                                                Reset Password
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                </Form>
                            </Col>
                            :
                            <Col className="mx-auto" lg="4" md="8">
                                <Form action="" className="form" method="">
                                    <Card className={"card-login " + cardClasses}>
                                        <Card.Header>
                                            <div>
                                                <Link to="/auth/login">
                                                    <img
                                                        style={{ width: 10 }}
                                                        className="ms-2 mt-1"
                                                        src={require("assets/svg/angle-left-thin.svg").default}
                                                        alt="aldrich-logo"
                                                    />
                                                </Link>
                                                <div className="login-img text-center pt-2">
                                                    <img
                                                        src={require("assets/img/logo.png").default}
                                                        alt="aldrich-logo"
                                                    />
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Body>
                                                <p>If an account is registered at the entered email address details on how to reset your password will be sent there.</p>
                                            </Card.Body>
                                        </Card.Body>
                                        <Card.Footer className="ml-auto mr-auto text-center">
                                            <Button
                                                className="btn-wd"
                                                variant="primary"
                                                onClick={event => window.location.href = '/auth/login'}
                                            >
                                                Go Back
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                </Form>
                            </Col>
                        }

                    </Container>
                </div>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: "url(" + require("assets/img/background.jpg").default + ")"
                    }}
                ></div>
            </div>
        </>
    );
}

export default ResetPasswordPage;

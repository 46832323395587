
// User Management
export const ADMIN_USERMANAGEMENT_VIEW = "UserManagement.View";
export const ADMIN_USERMANAGEMENT_EDIT = "UserManagement.Edit";
export const ADMIN_USERMANAGEMENT_DELETE = "UserManagement.Delete";

// Audit Log
export const AUDITLOG_VIEW = "AuditLog.View";

// Supplier
export const SUPPLIER_VIEW = "Suppliers.View";
export const SUPPLIER_EDIT = "Suppliers.Edit";
export const SUPPLIER_DELETE = "Suppliers.Delete";

// Labels
export const LABELS_VIEW = "Labels.View";

// Location
export const LOCATION_VIEW = "Locations.View";
export const LOCATION_EDIT = "Locations.Edit";
export const LOCATION_DELETE = "Locations.Delete";

// Products
export const PRODUCTS_VIEW = "Products.View";
export const PRODUCTS_EDIT = "Products.Edit";
export const PRODUCTS_DELETE = "Products.Delete";
export const PRODUCTS_FULL_VIEW = "Products.FullView";
export const PRODUCTS_WEB = "Products.Web";

// Categories
export const CATEGORIES_VIEW = "Categories.View";
export const CATEGORIES_EDIT = "Categories.Edit";
export const CATEGORIES_DELETE = "Categories.Delete";

// Movements
export const MOVEMENTS_VIEW = "Movements.View";
export const MOVEMENTS_EDIT = "Movements.Edit";
export const MOVEMENTS_DELETE = "Movements.Delete";

// Buyers
export const BUYERS_VIEW = "Buyers.View";
export const BUYERS_EDIT = "Buyers.Edit";
export const BUYERS_DELETE = "Buyers.Delete";

// BulkUpdate
export const BULKUPDATE_VIEW = "BulkUpdate.View";
export const BULKUPDATE_EXPORT = "BulkUpdate.Export";
export const BULKUPDATE_IMPORT = "BulkUpdate.Import";

// Purchase Order
export const PURCHASEORDER_VIEW = "PurchaseOrder.View";
export const PURCHASEORDER_EDIT = "PurchaseOrder.Edit";
export const PURCHASEORDER_DELETE = "PurchaseOrder.Delete";


import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container
} from "react-bootstrap";

import { SUPPLIER_EDIT, SUPPLIER_DELETE } from '../../constants/Permissions.js';
import { getPermission } from '../../utils/Permissions.js';

function SupplierTable(props) {
    const user = useSelector((state) => state.user);



    const handlePermission = () => {
        showSweetAlert('unauthorized');
    }

    const renderTableData = (permissionEdit, permissionDelete) => {
        return props.data.map((data, index) => {
            const { id, name, code, contactName, contactNumber, addressLine1, addressLine2, city, county, postcode, salesEmail, customerServiceEmail } = data
            return (
                <tr key={`Supplier${index}${id}`}>
                    <td className="text-center">{id}</td>
                    <td>{name}</td>
                    <td>{code}</td>
                    <td>{contactName}</td>
                    <td>{contactNumber}</td>
                    <td>{addressLine1}</td>
                    <td>{salesEmail}</td>
                    <td>{customerServiceEmail}</td>
                    <td className="td-actions text-right">
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-981231696">
                                    Edit supplier
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="success"
                                onClick={permissionEdit ? () => props.handleEdit(id) : () => handlePermission()}
                            >
                                <i className="fas fa-edit"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-255158527">Remove supplier</Tooltip>
                            }
                        >

                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="danger"
                                onClick={permissionDelete ? () => props.handleDelete(id) : () => handlePermission()}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        })
    }

    return (
        <>
            {alert}
            <Card className="table-with-links elysium-h100">
                <Card.Header>
                    <Card.Title as="h4" className="d-flex justify-content-between align-items-center">
                        Suppliers
                        {getPermission(user.permissions, SUPPLIER_EDIT) ?
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handleAdd()}
                            >
                                Add
                            </Button>
                            :
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handlePermission()}
                            >
                                Add
                            </Button>
                        }
                    </Card.Title>
                    <p className="card-category">
                    </p>
                </Card.Header>
                <Card.Body className="table-full-width elysium-h90">
                    <Col className="overflow-auto elysium-h100">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Contact Name</th>
                                    <th>Contact Number</th>
                                    <th>Address</th>
                                    <th>Sales Email</th>
                                    <th>Customer Service Email</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData(getPermission(user.permissions, SUPPLIER_EDIT), getPermission(user.permissions, SUPPLIER_DELETE))}
                            </tbody>
                        </Table>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
}

export default SupplierTable;

import React, { useState, useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card
} from "react-bootstrap";

function ApprovalCommentsInput(props) {
    const [comment, setComment] = useState(""); // Local state for the comment

    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, [])

    return (
        <>
            <Form className="form">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12"> {/* Assuming you want the comment input to span the entire width */}
                                <Form.Group>
                                    <label>Do you want to add comments to this approval?</label>
                                    <Form.Control
                                        value={comment}
                                        onChange={e => setComment(e.target.value)}
                                        placeholder="Add comment here"
                                        type="text"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button
                            className="btn-fill float-end"
                            variant="primary"
                            onClick={() => props.handleCommentConfirm(comment)} // Pass the comment to the confirm handler
                        >
                            Ok
                        </Button>
                        <Button
                            className="btn-fill float-end"
                            variant="info"
                            onClick={() => props.handleCancelApprovalComments(false)} // Close the modal
                        >
                            Cancel
                        </Button>
                    </Card.Body>
                </Card>
            </Form >
        </>
    )
}
export default ApprovalCommentsInput;

import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Row,
    Col,
} from "react-bootstrap";

import CustomSelect from '../../components/Shared/CustomSelect';
import { showSweetAlert } from 'components/Shared/CustomAlert';

import { ADMIN_USERMANAGEMENT_VIEW, ADMIN_USERMANAGEMENT_EDIT, ADMIN_USERMANAGEMENT_DELETE } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

function UserPage(props) {
    const user = useSelector((state) => state.user);

    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '1200px');
    }, [])



    const [showModal, setShowModal] = useState(false);
    //hooks
    const handleCloseModal = () => setShowModal(false);

    const handleShowPassword = () => {
        setShowModal(true);
    }

    const handleShowCancel = () => {
        showSweetAlert('warning', `Are you sure you want to cancel, any changes will be lost?`, () => { handleConfirmCancel() });
        return;
    }


    const handleShowResetPassword = () => {
        showSweetAlert('warning', `Are you sure you want to reset this users password?`, () => { handlePasswordConfirm() });
        return;
    }

    const onResetPassword = async () => {
        try {
            let url = `/account/forgotpassword?email=${props.user.email}`;
            const response = await fetch(url, {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
            });

        } catch (err) {
        }
    }

    const handlePasswordConfirm = async () => {

        onResetPassword();
        props.handleCancel();
    }

    const handleShowQr = () => {
        showSweetAlert('warning', `Are you sure you want to resend this users QR code?`, () => { handleQrConfirm() });
        return;
    }


    const handleQrConfirm = () => {
        props.handleQr(props.userId);
        hideAlert();
    }

    const handleShowPin = () => {
        console.log(props)
        showSweetAlert('warning', `Are you sure you want to regenerate this users pin?`, () => { handlePinConfirm() });
        return;
    }

    const handlePinConfirm = () => {
        props.handlePin(props.user.id);
        hideAlert();
    }

    const hideAlert = () => {

    };

    const handleChangeImage = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            props.handleChange("avatar", reader.result.split(",").slice(1)[0]);
        }

        reader.readAsDataURL(file);
    }
    const handleClickImage = () => {

        let image = document.getElementById('ag-avatar-upload');
        image.click();
    }

    // ### HOOKS ###
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '1200px');
    }, [])

    return (
        <>
            {alert}
            <Row>
                <Col md="8">
                    <Form action="" className="form" method="">
                        <Card>
                            <Card.Body style={{ minHeight: "353px" }}>
                                {getPermission(user.permissions, ADMIN_USERMANAGEMENT_EDIT) &&
                                    <Row>
                                        <Col className="pr-1" md="12">
                                            <Button
                                                className="btn-link btn-xs btn-action float-end"
                                                variant="success"
                                                onClick={() => props.handleEdit()}
                                            >
                                                <i className="fas fa-edit"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <Form.Group>
                                            <label>Username</label>
                                            {
                                                props.userId === 0 ?
                                                    <Form.Control
                                                        placeholder="Username"
                                                        name="username"
                                                        type="text"
                                                        value={props.user.username}
                                                        onChange={(e) => props.handleChange("username", e.target.value)}
                                                        onBlur={(e) => props.validateInput("username", e.target.value)}
                                                    ></Form.Control>
                                                    :
                                                    <Form.Control
                                                        disabled
                                                        placeholder="Username"
                                                        name="username"
                                                        type="text"
                                                        value={props.user.username}
                                                        onChange={(e) => props.handleChange("username", e.target.value)}
                                                        onBlur={(e) => props.validateInput("username", e.target.value)}
                                                    ></Form.Control>
                                            }
                                        </Form.Group>
                                        {props.error.username && <label style={{ color: "red" }} className='err'>{props.error.username}</label>}

                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <label htmlFor="exampleInputEmail1">
                                                Email address
                                            </label>


                                            <Form.Control
                                                className="Email"
                                                placeholder="Email"
                                                type="email"
                                                name="email"
                                                value={props.user.email}
                                                disabled={!props.isEdit}
                                                onChange={(e) =>

                                                    props.handleChange("email", e.target.value)}
                                                onBlur={(e) =>

                                                    props.validateInput("email", e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                        {props.error.email && <label style={{ color: "red" }} className='err'>{props.error.email}</label>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-1" md="6">
                                        <Form.Group>
                                            <label>First Name</label>
                                            <Form.Control
                                                name="firstname"
                                                placeholder="First Name"
                                                type="text"
                                                value={props.user.firstName}
                                                disabled={!props.isEdit}
                                                onChange={(e) => props.handleChange("firstName", e.target.value)}
                                                onBlur={(e) => props.validateInput("firstName", e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                        {props.error.firstName && <label style={{ color: "red" }} className='err'>{props.error.firstName}</label>}
                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <label>Last Name</label>
                                            <Form.Control
                                                name="lastname"
                                                placeholder="Last Name"
                                                type="text"
                                                value={props.user.lastName}
                                                disabled={!props.isEdit}
                                                onChange={(e) => props.handleChange("lastName", e.target.value)}
                                                onBlur={(e) => props.validateInput("lastName", e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                        {props.error.lastName && <label style={{ color: "red" }} className='err'>{props.error.lastName}</label>}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="pr-1" md="6">
                                        <Form.Group>
                                            <label>Role</label>
                                            <CustomSelect
                                                isDisabled={!props.isEdit}
                                                placeholder="Role"
                                                name="role"
                                                id={props.user.roleId}
                                                disabled={!props.isEdit}
                                                selected={props.user.roleName}
                                                handleChange={(e) => props.handleChange("roleId", e.value)}
                                                data={props.roles}
                                                onBlur={(e) => props.validateInput("roleId", e.target.value)}
                                            />
                                        </Form.Group>
                                        {props.error.role && <label style={{ color: "red" }} className='err'>{props.error.role}</label>}
                                    </Col>
                                    <Col className="pl-1" md="6">
                                        <Form.Group>
                                            <label>Location</label>
                                            <CustomSelect
                                                isDisabled={!props.isEdit}
                                                placeholder="Location"
                                                id={props.user.locationId}
                                                selected={props.user.locationName}
                                                handleChange={(e) => props.handleChange("locationId", e.value)}
                                                data={props.locations}
                                                name="location"
                                            />
                                        </Form.Group>
                                        {props.error.location && <label style={{ color: "red" }} className='err'>{props.error.location}</label>}
                                    </Col>
                                </Row>


                                {props.userId === undefined ?
                                    <Button
                                        className="btn-fill float-start"
                                        variant="primary"
                                        onClick={() => props.setShowChangePassword(true)}
                                    >
                                        Change Password
                                    </Button>
                                    : props.userId !== 0 ?
                                        <Button
                                            className="btn-fill float-start"
                                            variant="primary"
                                            onClick={handleShowResetPassword}
                                        >
                                            Reset Password
                                        </Button>
                                        : ""
                                }
                                {props.userId !== 0 ?
                                    <>

                                        <Button
                                            className="btn-fill float-start"
                                            variant="primary"
                                            onClick={handleShowPin}
                                        >
                                            Regenerate Pin
                                        </Button>
                                    </>
                                    :
                                    ""
                                }

                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={props.handleShowSave}
                                >
                                    Save
                                </Button>

                                {props.handleShowCancel !== undefined && <Button
                                    className="btn-fill float-end"
                                    variant="info"
                                    onClick={handleShowCancel}
                                >
                                    Cancel
                                </Button>}
                                <div className="clearfix"></div>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
                <Col md="4">
                    <Card className="card-user">
                        <Card.Body>
                            <div className="author">
                                <a className="cursor-pointer"
                                    onClick={() => handleClickImage()}>
                                    <img
                                        alt="..."
                                        className="avatar border-gray"
                                        src={props.user.avatar && props.user.avatar !== "" ? "data:image/png;base64," + props.user.avatar : "/default-avatar.png"}
                                    />
                                    <Card.Title as="h5" className="text-decoration-underline pb-1">Change Avatar</Card.Title>
                                </a>

                                <input
                                    id="ag-avatar-upload"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={handleChangeImage}
                                    hidden
                                />

                            </div>

                        </Card.Body>

                    </Card>

                </Col>

            </Row>
        </>
    );
}

export default UserPage;

import React from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    Container
} from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


function BuyerCollectionProductList(props) {
    var buyersCollectionData = props.buyersCollectionData;
    return (
        <>
            <style>
                {`
         .row-cols-md-4>* {
            display: contents;
            width: auto;
        }
        `}
            </style>
            <Container>
                <div className="item-id" style={{ display: "none" }}>0</div>
                <Row xs={1} md={4} style={{ marginTop: "10px" }}>
                    {props.buyersCollectionData !== null &&
                        <DragDropContext onDragEnd={props.handleDragEnd}>
                            <Droppable droppableId="buyer-collection">

                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            buyersCollectionData.map((item, index) => (
                                                <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Col className="buyer-detail">
                                                                <Card style={{ marginBottom: "10px" }}>
                                                                   
                                                                    <Button
                                                                        className="btn-link btn-xs position-absolute top-0 end-0"
                                                                        variant="success"
                                                                        onClick={() => props.handleEditBuyersCollectionProductDetailModal(item)}
                                                                        style={{ width: "30px", top: "5px", right: "5px" }} // Set the width and position

                                                                    >
                                                                        <i className="fas fa-edit"></i>
                                                                    </Button>
                                                                    <Card.Body>

                                                                        <Row>
                                                                            <Col className="pr-1" style={{ width: "100%" }} md="6">
                                                                                <div className="card-label">
                                                                                    <Form.Label className="me-2 card-name">Name:</Form.Label>
                                                                                    <Form.Group>
                                                                                        <Form.Control
                                                                                            disabled
                                                                                            value={item.name}
                                                                                            placeholder="name"
                                                                                            type="text"
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="card-label">
                                                                                    <Form.Label className="me-2 card-name">Code:</Form.Label>
                                                                                    <Form.Group>
                                                                                        <Form.Control
                                                                                            disabled
                                                                                            value={item.brandCode}
                                                                                            placeholder="Code"
                                                                                            type="number"
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="card-label">
                                                                                    <Form.Label className="me-2 card-name">Cost:</Form.Label>
                                                                                    <Form.Group>
                                                                                        <Form.Control
                                                                                            disabled
                                                                                            value={item.cost}
                                                                                            placeholder="Cost"
                                                                                            type="number"
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="card-label">
                                                                                    <Form.Label className="me-2 card-name">RRP:</Form.Label>
                                                                                    <Form.Group>
                                                                                        <Form.Control
                                                                                            disabled
                                                                                            value={item.rrp}
                                                                                            placeholder="RRP"
                                                                                            type="number"
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}

                            </Droppable>
                        </DragDropContext>
                    }
                    <Col>
                        <Card className="add-model" >
                            <Card.Body className="d-flex align-items-center justify-content-center">

                                <Row>
                                    <Col className="pr-1" style={{ width: "100%" }} md="6" >
                                        <div>
                                            <Button
                                                className="btn-link btn-xs pull-right text-success"
                                                variant="danger"
                                                onClick={() => props.buyersCollectionProductAddModal()}
                                            >
                                                <i className="fas fa-plus" style={{ fontSize: "2rem" }}></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row xs={1} md={4} className="g-4" style={{ marginTop: "10px" }}>

                </Row>
            </Container>
        </>
    )
}
export default BuyerCollectionProductList;
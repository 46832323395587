import React from "react";
import { useEffect, useState } from 'react';
import { formatDate } from 'utils/FormatDate.js';

// react-bootstrap components
import {
    Form,
    Card,
    Col,
    Button,
    OverlayTrigger,
    Row,
    Table,
    Tooltip
} from "react-bootstrap";


function AuditLog(props) {
    // Render Audit Table
    const renderAuditTable = () => {
        if (props.auditLogs.length > 0) {
            {
                return (
                    <Table className="table-hover table-striped w-full">
                        <thead>
                            <tr>
                                <th>Timestamp</th>
                                <th>Username</th>
                                <th>Type Name</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.auditLogs.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{formatDate(item.timestamp)}</td>
                                        <td>{item.username}</td>
                                        <td>{item.typeName}</td>
                                        <td>{item.description}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )
            }
        }
    }

    return (
        <Card.Body className="table-responsive p-0 item-card-tab">
            <Col md="12">
                {renderAuditTable()}
            </Col>
        </Card.Body>
    );
}
export default AuditLog;

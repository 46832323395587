import UserPage from "views/Pages/UserPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Products from "views/Elysium/Products.js";
import Suppliers from "views/Elysium/Suppliers.js";
import Locations from "views/Elysium/Locations.js";
import {
    ADMIN_USERMANAGEMENT_VIEW,
    AUDITLOG_VIEW,
    SUPPLIER_VIEW,
    LABELS_VIEW,
    LOCATION_VIEW,
    PRODUCTS_VIEW,
    PURCHASEORDER_VIEW,
    CATEGORIES_VIEW,
    MOVEMENTS_VIEW,
    BUYERS_VIEW,
    BULKUPDATE_VIEW
} from 'constants/Permissions.js';

// User Management
import UserManagement from "views/Elysium/UserManagement.js";
import AccountManagement from "views/Elysium/AccountManagement.js";
import AuditLog from "views/Elysium/AuditLog.js";
import Categories from "views/Elysium/Category.js";
import ResetPasswordPage from "./views/Pages/ResetPasswordPage";
import PurchaseOrders from "./views/Elysium/PurchaseOrder.js";
import PurchaseOrderMapping from "./views/Elysium/PurchaseOrderMapping.js";
import Movements from "./views/Elysium/Movements";
import Labels from "./views/Elysium/Labels";
import AutoReplenishment from "views/Elysium/AutoReplenishment";
import Buyers from "views/Elysium/Buyers";
import BulkUpdate from "views/Elysium/BulkUpdate";
var routes = [
    {
        path: "/manage-products",
        name: "Products",
        permission: PRODUCTS_VIEW,
        icon: "nc-icon nc-layers-3 icon-bold",
        component: Products,
    },
    {
        collapse: true,
        path: "/purchase-orders",
        name: "Purchase Orders",
        permission: PURCHASEORDER_VIEW,
        state: "openPO",
        icon: "nc-icon nc-money-coins icon-bold",
        views: [
            {
                path: "/po-list",
                layout: "/admin",
                name: "List",
                mini: "L",
                component: PurchaseOrders,
            },
            {
                path: "/po-mappings",
                layout: "/admin",
                name: "Mappings",
                mini: "M",
                component: PurchaseOrderMapping,
            },
        ],
    },
    {
        path: "/manage-categories",
        name: "Categories",
        permission: CATEGORIES_VIEW,
        icon: "nc-icon nc-bullet-list-67 icon-bold",
        component: Categories,
    },
    {
        path: "/manage-locations",
        name: "Locations",
        permission: LOCATION_VIEW,
        icon: "nc-icon nc-square-pin icon-bold",
        component: Locations,
    },
    {
        path: "/print-labels",
        name: "Labels",
        permission: LABELS_VIEW,
        icon: "nc-icon nc-tag-content icon-bold",
        component: Labels,
    },
    {
        path: "/manage-suppliers",
        name: "Suppliers",
        permission: SUPPLIER_VIEW,
        icon: "nc-icon nc-delivery-fast icon-bold",
        component: Suppliers,
    },
    {
        path: "/audit-log",
        name: "Audit Logs",
        permission: AUDITLOG_VIEW,
        icon: "nc-icon nc-notes icon-bold",
        component: AuditLog,
    },

    {
        path: "/manage-movements",
        name: "Movements",
        permission: MOVEMENTS_VIEW,
        icon: "nc-icon nc-refresh-02 icon-bold",
        component: Movements,
    },
    {
        path: "/buyers",
        name: "Buyers App",
        permission: BUYERS_VIEW,
        icon: "nc-icon nc-cart-simple icon-bold",
        component: Buyers,
    },
    {
        path: "/bulk-update",
        name: "Bulk Update",
        permission: BULKUPDATE_VIEW,
        icon: "nc-icon nc-cloud-upload-94 icon-bold",
        component: BulkUpdate,
    },

    // //non nav
    {
        path: "/admin/manage-user",
        name: "User Management",
        icon: "nc-icon nc-watch-time",
        permission: ADMIN_USERMANAGEMENT_VIEW,
        component: UserManagement,
    },
    {
        path: "/manage-account",
        name: "Account Management",
        icon: "nc-icon nc-watch-time",
        component: AccountManagement,
    },
    {
        path: "/auth/user-page",
        layout: "/auth",
        name: "User Page",
        component: UserPage,
    },
    {
        path: "/auth/login",
        layout: "/auth",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/auth/reset-password",
        layout: "/auth",
        name: "Reset Password",
        component: ResetPasswordPage,
    }
];
export default routes;
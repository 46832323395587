import React from "react";
import { useEffect, useState } from 'react';
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Button,
    Col,
    Row,
    Form
} from "react-bootstrap";

function AddEditSupplier(props) {
    

   
    const handleCancel = () => {
        props.handleCancel();
    }

    return (
        <>
            {alert}
            <Form action="" className="form" method="">
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Supplier Name</label>

                            <Form.Control
                                placeholder="Supplier Name"
                                type="text"
                                value={props.data.name}
                                onChange={(e) => props.handleChange("name", e.target.value)}
                                onBlur={(e) => props.validateInput("name", e.target.value)}
                            />
                                                        {props.validation.name && <label style={{ color: "red" }} className='err'>{props.validation.name}</label>}
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Code</label>

                            <Form.Control
                                placeholder="Code"
                                type="text"
                                value={props.data.code}
                                onChange={(e) => props.handleChange("code", e.target.value)}
                                onBlur={(e) => props.validateInput("code", e.target.value)}
                            />
                            {props.validation.code && <label style={{ color: "red" }} className='err'>{props.validation.code}</label>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Contact Name</label>
                            <Form.Control
                                placeholder="Contact Name"
                                type="text"
                                value={props.data.contactName}
                                onChange={(e) => props.handleChange("contactName", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Contact Number</label>
                            <Form.Control
                                placeholder="Contact Number"
                                type="text"
                                value={props.data.contactNumber}
                                onChange={(e) => props.handleChange("contactNumber", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Address Line 1</label>
                            <Form.Control
                                placeholder="Address Line 1"
                                type="text"
                                value={props.data.addressLine1}
                                onChange={(e) => props.handleChange("addressLine1", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Address Line 2</label>
                            <Form.Control
                                placeholder="Address Line 2"
                                type="text"
                                value={props.data.addressLine2}
                                onChange={(e) => props.handleChange("addressLine2", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>City</label>
                            <Form.Control
                                placeholder="City"
                                type="text"
                                value={props.data.city}
                                onChange={(e) => props.handleChange("city", e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>County</label>

                            <Form.Control
                                placeholder="County"
                                type="text"
                                value={props.data.county}
                                onChange={(e) => props.handleChange("county", e.target.value)}
                            />

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Postcode</label>

                            <Form.Control
                                placeholder="Postcode"
                                type="text"
                                value={props.data.postcode}
                                onChange={(e) => props.handleChange("postcode", e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Sales Email</label>

                            <Form.Control
                                placeholder="Sales Email"
                                type="text"
                                value={props.data.salesEmail}
                                onChange={(e) => props.handleChange("salesEmail", e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Customer Service Email</label>

                            <Form.Control
                                placeholder="Customer Service Email"
                                type="text"
                                value={props.data.customerServiceEmail}
                                onChange={(e) => props.handleChange("customerServiceEmail", e.target.value)}
                            />

                        </Form.Group>
                    </Col>
                </Row>

                <Button
                    className="btn-fill float-end"
                    variant="primary"
                    onClick={props.handleAddSupplier}
                >
                    Save
                </Button>

                <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>

                <div className="clearfix"></div>
            </Form >
        </>
    );
}

export default AddEditSupplier;

import { createStore } from 'redux'

const initialState = {
    user: {
        id: 0,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        roleId: 0,
        locationId: 0,
        permissions: [],
        avatar:"",
        roleName:"",
    },
    isFavourites: false,
    isSideBarMini: false,

    IsUserNameValid:false,
    IsEmailValid: false,

    Search:"",
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }      
            case 'SetUsernameValid':
                return { ...state, ...rest }  
        default:
            return state
    }
}

const store = createStore(changeState)
export default store

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import AccountManagement from "views/Elysium/AccountManagement";
import ChangePassword from "../UserManagement/ChangePassword";
import CustomModal from "components/Shared/CustomModal";
// react-bootstrap components
import {
    Button,
    Dropdown,
    Navbar,
    Nav,
    Container,
} from "react-bootstrap";

import { getPermission } from 'utils/Permissions.js';
import {
    ADMIN_USERMANAGEMENT_VIEW
} from 'constants/Permissions.js';

function AdminNavbar() {
    const location = useLocation();
    const history = useHistory()
    const [showAccountModal, setShowAccountModal] = useState(false);
    const handleCloseAccountModal = () => setShowAccountModal(false);
    const handleShowAccountModal = () => setShowAccountModal(true);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const handleClosePasswordModal = () => setShowPasswordModal(false);
    const handleShowPasswordModal = () => setShowPasswordModal(true);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isSideBarMini = useSelector((state) => state.isSideBarMini);

    const [collapseOpen, setCollapseOpen] = useState(false);
    const onLogOut = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("userToken");
        localStorage.removeItem("username");
        history.push("/auth/login");
    }

    useEffect(() => {
        const fetchUser = async () => {
            try {
                let url = `/user/getsingle?id=${localStorage.getItem("userId")}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch(url);
                const user = await response.json();

                dispatch({ type: 'set', user: user });
            } catch (err) {
            }
        }

        fetchUser();
    }, [])

    return (
        <>
            <Navbar expand="lg">
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-minimize">
                            <Button
                                className="btn-fill btn-round btn-icon d-none d-lg-block bg-light border-dark"
                                variant="light"
                                onClick={() => { document.body.classList.toggle("sidebar-mini"); dispatch({ type: 'set', isSideBarMini: !isSideBarMini }); }}
                            >
                                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                                <i className="fas fa-bars visible-on-sidebar-mini"></i>
                            </Button>
                            <Button
                                className="btn-fill btn-round btn-icon d-block d-lg-none border-dark"
                                variant="dark"
                                onClick={() =>
                                    document.documentElement.classList.toggle("nav-open")
                                }
                            >
                                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                                <i className="fas fa-bars visible-on-sidebar-mini"></i>
                            </Button>
                        </div>
                    </div>
                    <button
                        className="navbar-toggler navbar-toggler-right border-0"
                        type="button"
                        onClick={() => setCollapseOpen(!collapseOpen)}
                    >
                        <span className="navbar-toggler-bar burger-lines"></span>
                        <span className="navbar-toggler-bar burger-lines"></span>
                        <span className="navbar-toggler-bar burger-lines"></span>
                    </button>
                    <Navbar.Collapse className="justify-content-end align-items-center fpm-nav" in={collapseOpen}>
                        {/*<Nav className="nav mr-auto" navbar>
                            <Form
                                className="navbar-form navbar-left navbar-search-form ml-3 ml-lg-0"
                                role="search"
                            >
                                <InputGroup>
                                    <i className="nc-icon nc-zoom-split"></i>
                                    <Form.Control
                                        defaultValue=""
                                        placeholder="Search..."
                                        type="text"
                                    ></Form.Control>
                                </InputGroup>
                            </Form>
                        </Nav>*/}
                        <Nav navbar className="d-flex align-items-center">

                            {getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) &&
                                <Dropdown as={Nav.Item} className="d-flex align-items-center">
                                    <Dropdown.Toggle
                                        as={Nav.Link}
                                        id="dropdown-41471887333"
                                        className="py-2"
                                        variant="default"
                                    >
                                        <i className="nc-icon nc-bullet-list-67"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        style={{ top: 40, right: 0 }}
                                        alignRight
                                        aria-labelledby="navbarDropdownMenuLink"
                                    >
                                        <Dropdown.Item
                                            onClick={() => history.push(`/admin/manage-user`)}
                                        >
                                            User Management
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            <Dropdown as={Nav.Item} className="d-flex align-items-center">
                                <Dropdown.Toggle
                                    as={Nav.Link}
                                    id="dropdown-41471887333"
                                    className="py-2"
                                    variant="default"
                                >
                                    <div className="ag_navbar">
                                        <div className="avatar">
                                            <img alt="..." src={user.avatar && user.avatar !== "" ? "data:image/png;base64," + user.avatar : "/default-avatar.png"} />
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    style={{ top: 40, right: 0 }}
                                    alignRight
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <Dropdown.Item
                                        //onClick={() => history.push(`/manage-account`)}
                                        onClick={() => handleShowAccountModal()}
                                    >
                                        My Account
                                    </Dropdown.Item>
                                    <div className="divider"></div>
                                    <Dropdown.Item
                                        onClick={() => onLogOut()}
                                    >
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <CustomModal
                show={showAccountModal}
                handleClose={handleCloseAccountModal}
                title="Account Managment"
                backdrop="static">
                <AccountManagement
                />
            </CustomModal>
            <CustomModal
                show={showPasswordModal}
                handleClose={handleClosePasswordModal}
                title="Change Password">
                <ChangePassword />
            </CustomModal>
        </>
    );
}

export default AdminNavbar;

import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import moment from 'moment';
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { ADMIN_USERMANAGEMENT_EDIT, ADMIN_USERMANAGEMENT_DELETE } from '../../constants/Permissions.js';
import { getPermission } from '../../utils/Permissions.js';

function AuditLogTable(props) {
    const user = useSelector((state) => state.user);

    const renderTableData = () => {
        return props.data.map((log, index) => {
            const { timestamp, username, typeName, prodcutId, description } = log
            return (
                <tr key={`AuditLog${index}`}>
                    <td>{moment(timestamp).format("DD/MM/YYYY hh:mm:ss")}</td>
                    <td>{username}</td>
                    <td>{typeName}</td>
                    <td>{prodcutId === 0 ? "N/A" : prodcutId}</td>
                    <td>{description}</td>
                </tr>
            )
        })
    }

    return (
        <>
            {alert}
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4">Audit Logs</Card.Title>
                    <p className="card-category">
                    </p>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    {props.isLoading ?
                        <div style={{ height: 550 }} className="d-flex justify-content-center align-items-center text-center">
                            <div>
                                <Spinner animation="border" />
                                <p>Loading...</p>
                            </div>
                        </div> :
                        <>
                            {(props.data && props.data.length == 0) &&
                                (<p
                                    style={{ height: 550 }}
                                    className="d-flex justify-content-center align-items-center text-center"
                                >
                                    No data found.
                                </p>)}
                        </>
                    }
                    {props.data && props.data.length > 0 && (
                        < Col md="12">
                            <InfiniteScroll
                                dataLength={props.data.length}
                                next={props.updateData}
                                hasMore={true}
                                loader={props.data.length >= 20 ? <h6>Loading more data...</h6> : ""}
                                height={550}
                            >
                                <Table className="table-hover table-striped w-full">
                                    <thead>
                                        <tr>
                                            <th>Timestamp</th>
                                            <th>Username</th>
                                            <th>Type Name</th>
                                            <th>Product Id</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderTableData()}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </Col>)}
                </Card.Body>
            </Card>
        </>
    );
}

export default AuditLogTable;

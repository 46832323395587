import React from "react";
import { useEffect, useState } from 'react';
import CustomSelect from "components/Shared/CustomSelect";

// react-bootstrap components
import {
    Form,
    Card,
    Col,
    Button,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container
    , Dropdown
} from "react-bootstrap";

import { formatCurrency } from 'utils/FormatCurrency.js';

function SalesAndInventory(props) {
    console.log("Props", props)
    const renderInventoryTable = () => {
        if (props.inventoryData.length > 0) {
            {
                return (
                    <Table className="table-hover table-striped w-full">
                        <thead>
                            <tr>
                                <th>Location</th>
                                {props.inventoryData.length > 0 && props.inventoryData[0].sizesInStockAndSold.map(size => (
                                    <th key={size}>{size.sizeName}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {props.inventoryData.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.locationName}</td>
                                        {
                                            props.inventoryData.length > 0 && props.inventoryData[i].sizesInStockAndSold.map((size, i) => (
                                                <td key={i}>{`${size.inStock} / ${size.inTransit} (${size.sold}`})</td>
                                            ))
                                        }
                                        <td className="td-actions text-right">
                                            {props.canEdit &&
                                                <Button
                                                    className="btn-link btn-xs btn-action"
                                                    variant="danger"

                                                    onClick={() => props.handleShowInventoryModal(item)}
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )
            }
        }
    }

    return (
        <Card.Body className="table-responsive p-0 item-card-tab">
            <Col md="12">
                <Form action="" className="form" method="">
                    <Row>
                        <Col md="4">
                            <h4>Sales</h4>
                            <Col className="" md="12">
                                <Form.Group>
                                    <label>Cost</label>
                                    <Form.Control
                                        className=""
                                        placeholder="Cost"
                                        type="text"
                                        value={props.hasFullViewPermission ? formatCurrency(props.salesData.cost) : formatCurrency(0)}
                                        disabled
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="12">
                                <Form.Group>
                                    <label>Sales</label>
                                    <Form.Control
                                        placeholder="Sales (After Tax)"
                                        type="text"
                                        value={formatCurrency(props.salesData.sales)}
                                        disabled
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="12">
                                <Form.Group>
                                    <label>Profit</label>
                                    <Form.Control
                                        placeholder="Profit"
                                        type="text"
                                        value={formatCurrency(props.salesData.profit)}
                                        disabled
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="12">
                                <Form.Group>
                                    <label>Qty</label>
                                    <Form.Control
                                        placeholder="Qty"
                                        type="number"
                                        value={props.salesData.quantity}
                                        disabled
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col md="8">
                            <Row>
                                <Col md="2">
                                    <h4>Inventory</h4>
                                </Col>
                                <Col md="4">
                                    <Form.Group>
                                        <label>Season</label>
                                        <CustomSelect
                                            placeholder="Season"
                                            value={props.selectedSeasonId}
                                            data={props.seasons}
                                            handleChange={(e) => props.handleSeasonChange(e)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <Card.Body className="table-responsive p-0 inventory-table">
                                <Col md="12">
                                    {renderInventoryTable()}
                                </Col>
                            </Card.Body>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Card.Body>
    );
}
export default SalesAndInventory;
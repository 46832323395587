import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    Container,
    Row,
    Col,
    Button,
    Form, Tooltip, OverlayTrigger
} from "react-bootstrap";
import DateRange from '../../components/Shared/DateRange';
import CustomSelect from '../../components/Shared/CustomSelect';
import MovementsTable from "../../components/Movements/MovementsTable";
import MovementsModal from "../../components/Movements/MovementsModal";
import { useSelector } from 'react-redux';
import moment from 'moment';
import AddEditMovementsModal from "components/Movements/AddEditMovementsModal";
import AddEditMovementProduct from "components/Movements/AddEditMovementProduct";
import CustomModal from "../../components/Shared/CustomModal";
import { showSweetAlert } from 'components/Shared/CustomAlert';
import InventoryModal from "../../components/ItemCard/InventoryModal";
import ItemCard from "../../components/ItemCard/ItemCard";


import { MOVEMENTS_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';
function Movements() {
    // ### STATE ###
    const movementSearch = useSelector((state) => state.Search);
    const user = useSelector((state) => state.user);
    
    const [search, setSearch] = useState("");

    const defaultDate = new Date();
    const [dateRange, setDateRange] = useState([defaultDate.setMonth(defaultDate.getMonth() - 3), new Date()]);

    const [locationFromId, setLocationFromId] = useState(0);
    const handleLocationFromChange = (id) => {
        setLocationFromId(id);
        localStorage.setItem('locationFromId', id);
    };

    const [locationToId, setLocationToId] = useState(0);
    const handleLocationToChange = (id) => {
        setLocationToId(id);
        localStorage.setItem('locationToId', id);
    };

    const [statusId, setstatusId] = useState(0);
    const handleStatusChange = (id) => {
        setstatusId(id);
        localStorage.setItem('statusId', id);
    };

    const [typeId, settypeId] = useState(0);
    const handleTypeChange = (id) => {
        settypeId(id);
        localStorage.setItem('typeId', id);
    };

    const [locations, setLocations] = useState([]);
    const [getLocationDropdown, setLocationDropdown] = useState([]);
    const [orderBy, setOrderBy] = useState("DateAmended");
    const [filterDateData, setfilterDateData] = useState([]);
    const [descending, setDescending] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(20);
    const [originBatchData, setOriginBatchData] = useState([]);
    const [singleMovement, setsingleMovement] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const handleCloseBatchModal = () => setShowBatchModal(false);
    const handleShowBatchModal = () => setShowBatchModal(true);

    const [showItemCardModal, setShowItemCardModal] = useState(false);
    const [showInventoryModal, setShowInventoryViewModal] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(0);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [itemCardSelectedTab, setItemCardSelectedTab] = useState('Detail');

    const [showMovementProductModal, setShowMovementProductModal] = useState(false);
    const handleCloseMovementProductModal = () => setShowMovementProductModal(false);
    const handleShowMovementProductModal = () => setShowMovementProductModal(true);
    const [movementId, setMovementId] = useState(0);
    const [MovementsProductData, setMovementsProductData] = useState([]);
    const [originMovementsProducts, setoriginMovementsProducts] = useState([]);
    const [productData, setProductdata] = useState([]);
    const [productValue, setProductValue] = useState([]);
    const [quantityInStock, setQuantityInStock] = useState(0);
    const [movementChangedValue, setMovementChangedValue] = useState([]);
    const [originData, setOriginData] = useState([]);
    const movementTypes = [
        { id: 1, value: 'Allocation Delivery' },
        { id: 2, value: 'Replenishment' },
        { id: 3, value: 'Intercompany Transfer' }
    ];

    const status = [
        { id: 1, value: 'Open' },
        { id: 2, value: 'Open - Scanner' },
        { id: 3, value: 'InTransit' },
        { id: 4, value: 'Received' },
        { id: 5, value: 'Closed' },
    ];


    const [error, setError] = useState({
        fromLocationId: '',
        toLocationId: '',
        productId: ''
    })

    const validateInput = (name, value) => {
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "fromLocationId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;

                case "toLocationId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                case "productId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    }
    // ### HANDLERS ###
    const movementsModel = (movementId) => {
        console.log(movementId)
        setMovementId(movementId)
        fetchSingleMovement(movementId);
        fetchMovementProducts(movementId);
        handleShowModal();
    }

    const handleAdd = () => {
        setError([]);
        setMovementChangedValue({
            id: 0,
            batchName: "",
            fromLocationId: 0,
            fromLocationName: "",
            toLocationId: 0,
            toLocationName: "",
            fromNotes: "",
            toNotes: "",
            typeId: 3,
            typeName: "",
            statusId: 0,
            hasCreatedBatch: true
        })
        handleShowBatchModal();
    }
    const handleAddMovementProduct = () => {
        setError([]);
        setProductValue({
            id: 0,
            movementId: movementId,
            productId: 0,
            productName: "string",
            quantity: 0,
            sent: 0,
            received: 0
        })
        handleShowMovementProductModal();

    }
    const handleDatesChange = (dates) => {
        setDateRange(dates);
        if (dates[0] && dates[1]) {
            const filterData = originData.filter((item) => {
                return moment(item.timestamp)
                    .isBetween(
                        moment(dates[0], 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DDTHH:mm:ssZ'),
                        moment(dates[1], 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DDTHH:mm:ssZ'),
                        null,
                        '[]',
                    );
            });
            setfilterDateData(filterData);
            localStorage.setItem('dateRange', filterData);
        }
    };

    const handleSearchChange = (value) => {

        const filterData = originMovementsProducts?.filter((item) => item.productName.toLowerCase().includes(value.toLowerCase()));
        setSearch(value);
        setMovementsProductData(filterData);
    }
    // ### DATA FETCHES ###
    const fetchLocations = async () => {
        try {
            let url = `/location/getall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`; //todo - move to store
            const response = await fetch(url);
            const locations = await response.json();

            const data = locations?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocations(data);

        } catch (err) {
        }
    }
    const fetchLocationDropdown = async () => {

        try {
            let url = `/location/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const locations = await response.json();

            const products = locations?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocationDropdown(products);

        } catch (err) {
        }
    }
    const fetchGetallpaged = async () => {
        setPage(0);
        setOriginBatchData([]);
        setData([]);
        setIsLoading(true);

        const locationFromId = localStorage.getItem('locationFromId');
        const locationToId = localStorage.getItem('locationToId');
        const typeId = localStorage.getItem('typeId');
        const statusId = localStorage.getItem('statusId');
        const dateRange = localStorage.getItem('dateRange');

        try {


            let from = new Date(dateRange[0]);

            let url = `/Movement/getallpaged?skip=${skip}&take=${500}&fromLocationId=${locationFromId}&toLocationId=${locationToId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&orderBy=${orderBy}&descending=${descending}&search=${movementSearch}&typeId=${typeId}&statusId=${statusId}`;

            const response = await fetch(url);
            const body = await response.json();
            setIsLoading(false);
            setOriginBatchData(body);
            setData(body);
            setOriginData(body)
        } catch (err) {
            setIsLoading(false);
        }
    }

    const fetchData = async () => {
        //setIsLoading(true);
        try {
            let url = `/Movement/getallpaged?skip=${skip}&take=${take}&fromLocationId=${locationFromId}&toLocationId=${locationToId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&orderBy=${orderBy}&descending=${descending}&search=${movementSearch}&typeId=${typeId}&statusId=${statusId}`;


            const response = await fetch(url);

            const body = await response.json();
            setIsLoading(false);
            setOriginBatchData(body);
            setData(body);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchSingleMovement = async (id) => {
        try {
            let url = `/Movement/GetSingle?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            setsingleMovement(body);

        } catch (err) {
        }
    }
    const fetchMovementProducts = async (id) => {
        try {
            let url = `/MovementProduct/getall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&movementId=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            setMovementsProductData(body);
            setoriginMovementsProducts(body);
        } catch (err) {
        }
    }

    const fetchProductsDropdown = async () => {
        let locationId = singleMovement?.fromLocationId === undefined ? 0 : singleMovement?.fromLocationId
        try {
            let url = `/Product/GetProductDropdownListWithQuantity?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&locationId=${locationId}`;
            const response = await fetch(url);
            const body = await response.json();
            const product = body?.map((item) => {
                return { id: item.id, value: item.name, qty: item.quantityInStock };
            });
            setProductdata(product)
        } catch (err) {
        }
    }

    const handleChangeProduct = (name, value) => {
        let updatedProductValue = {
            ...productValue,
            [name]: value
        };
        setProductValue(updatedProductValue);

        const productWithId = productData.find(product => product.id == value);
        if (productWithId) {
            setQuantityInStock(productWithId.qty);
        } 

        validateInput(name, value);
    }

    const handleChangeLocation = (name, value) => {
        let item = {
            ...movementChangedValue,
            [name]: value,
        };
        setMovementChangedValue(item);
        validateInput(name, value);
    }
    const handleSaveMovement = () => {
        showSweetAlert('warning', `Are you sure you want to save the Movement?`, () => { saveMovement() });
    }

    const handleInTransitMovement = (id) => {
        showSweetAlert('warning', `Are you sure you want to mark this Movement as In Transit?`, () => { updateMovementStatus(id, 2) });
    }

    const handleCloseMovement = (id) => {
        showSweetAlert('warning', `Are you sure you want to close the Movement?`, () => { updateMovementStatus(id, 4) });
    }

    const handleGetItemCard = (id) => {
        setSelectedProductId(id);
        setShowItemCardModal(true);
    }

    const handleItemCardCloseModal = () => {
        setShowItemCardModal(false);
        setSelectedProductId(0);
    }

    const handleShowInventoryModal = (item) => {
        setSelectedInventory(item)
        setShowInventoryViewModal(true);
        setShowItemCardModal(false);
    }

    const handleHideInventoryModal = () => {
        setShowInventoryViewModal(false);
        setShowItemCardModal(true);
    }

    const saveMovement = async () => {
        
        if (movementChangedValue.fromLocationId <= 0) {
            error.fromLocationId = "Please select atleast one value";
        }
        if (movementChangedValue.toLocationId <= 0) {
            error.toLocationId = "Please select atleast one value";
        }
        if (movementChangedValue.fromLocationId <= 0 && movementChangedValue.toLocationId <= 0) {
            return;
        }
        try {
            let url = `Movement/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch((url), {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(movementChangedValue),

            });
            const body = await response.json();
            if (response.status == 200) {
                handleCloseBatchModal()
                showSweetAlert('saveSuccess', `Movement Product`);
            } else {
                showSweetAlert('serverError');
            }
            fetchGetallpaged()
            handleCloseBatchModal()
        } catch (err) {
        }
    }

    const updateMovementStatus = async (id, statusId) => {
        
        try {
            let url = `Movement/UpdateMovementStatus?statusId=${statusId}&batchId=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch((url), {
                method: 'put',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ]
            });

            if (response.status == 200) {
                handleCloseBatchModal()
                showSweetAlert('saveSuccess', `Movement`);
                fetchGetallpaged()

            } else {
                showSweetAlert('serverError');
            }
            fetchGetallpaged()
        } catch (err) {
        }
    }

    const handleSaveProduct = () => {
        if (productValue.quantity <= quantityInStock) {
            showSweetAlert('warning', `Are you sure you want to Save the Movement Product?`, () => { SaveProduct() });
        }
        else {
            showSweetAlert('warningOkOnly', `Selected location does not have enough of this item in stock.`);
        }
        
    }
    const SaveProduct = async () => {
        
        if (productValue.productId <= 0) {
            error.productId = "Please select atleast one value";
            return
        }
        try {
            let url = `MovementProduct/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch((url), {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(productValue),
            });
            const body = await response.json();
            if (response.status == 200) {
                showSweetAlert('saveSuccess', `Product Value`);
                fetchMovementProducts(movementId)
                handleCloseMovementProductModal();
            } else {
                showSweetAlert('serverError');
            }
            fetchMovementProducts(movementId)
            handleCloseMovementProductModal();
        } catch (err) {
        }
    }

    //Print batch in pdf
    const handlePrintBatchLabel = async (batchId, batchName) => {
        try {
            const url = `Movement/PrintBatchLabel?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&batchId=${batchId}`
            const response = await fetch(url);

            if (response.status == 200) {
                const base64String = await response.text();

                try {
                    const decodedData = Buffer.from(base64String, "base64").toString("binary");
                    const dataArray = new Uint8Array(Array.from(decodedData, (char) => char.charCodeAt(0)));
                    const blob = new Blob([dataArray], { type: "application/pdf" });
                    console.log("Blob created successfully");

                    const blobUrl = window.URL.createObjectURL(blob);
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        console.log("Iframe loaded, attempting to print");
                        try {
                            iframe.contentWindow.print();
                        } catch (printError) {
                            console.error("Error in printing:", printError);
                        }
                    };
                } catch (error) {
                    console.error("Error in processing the PDF:", error);
                }
            } else {
                const body = await response.text();
                showSweetAlert('warningCustomMessage', body);
            }
        }
        catch (e) {
            showSweetAlert('serverError');
        }
    }

    //Print batch labels to pdf
    const handleBatchProductLabelPDF = async (batchId, batchName) => {
        try {
            const url = `Movement/PrintBatchProductLabels?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&batchId=${batchId}`
            const response = await fetch(url);

            if (response.status == 200) {
                const base64String = await response.text();

                try {
                    const decodedData = Buffer.from(base64String, "base64").toString("binary");
                    const dataArray = new Uint8Array(Array.from(decodedData, (char) => char.charCodeAt(0)));
                    const blob = new Blob([dataArray], { type: "application/pdf" });
                    console.log("Blob created successfully");

                    const blobUrl = window.URL.createObjectURL(blob);
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        console.log("Iframe loaded, attempting to print");
                        try {
                            iframe.contentWindow.print();
                        } catch (printError) {
                            console.error("Error in printing:", printError);
                        }
                    };
                } catch (error) {
                    console.error("Error in processing the PDF:", error);
                }
            } else {
                const body = await response.text();
                showSweetAlert('warningCustomMessage', body);
            }
        }
        catch (e) {
            showSweetAlert('serverError');
        }
    }


    // ### HOOKS ###

    useEffect(() => {
        fetchLocationDropdown();
        fetchLocations();

        localStorage.setItem('locationFromId', 0);
        localStorage.setItem('locationToId', 0);
        localStorage.setItem('typeId', 0);
        localStorage.setItem('statusId', 0);
        localStorage.setItem('dateRange', 0);

       // fetchData();
       // fetchData();
        fetchGetallpaged();
        //const refreshInterval = 30000; // 30 seconds

        //const refreshData = () => {
        //    fetchGetallpaged(); // Fetch data every 5 seconds
        //    setTimeout(refreshData, refreshInterval); // Schedule the next refresh
        //};

        // Start the periodic refresh
        //const refreshTimeoutId = setTimeout(refreshData, refreshInterval);

        //// Cleanup the timeout when the component unmounts
        //return () => clearTimeout(refreshTimeoutId);
    }, [])

    useEffect(() => {
        fetchProductsDropdown();
    }, [singleMovement])

    useEffect(() => {
        fetchGetallpaged()
    }, [locationFromId, locationToId, typeId, statusId, movementSearch, dateRange])

    return (
        <>
            <CustomModal
                show={showItemCardModal}
                handleClose={handleItemCardCloseModal}
                title=""
                backdrop="static">
                <ItemCard
                    id={selectedProductId}
                    handleShowInventoryModal={handleShowInventoryModal}
                    selectedInventory={selectedInventory}
                    itemCardSelectedTab={itemCardSelectedTab}
                    setItemCardSelectedTab={setItemCardSelectedTab}
                />
            </CustomModal>
            <CustomModal
                show={showInventoryModal}
                handleClose={handleHideInventoryModal}
                title=""
                backdrop="static">
                <InventoryModal
                    selectedInventory={selectedInventory}
                    handleHideInventoryModal={handleHideInventoryModal}
                />
            </CustomModal>
            {
                showMovementProductModal == false &&
                <CustomModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    title="">
                    <MovementsModal
                        SingleMovement={singleMovement}
                        isLoading={isLoading}
                        MovementsProductData={MovementsProductData}
                        search={search}
                        handleSearchChange={handleSearchChange}
                        handleAddMovementProduct={handleAddMovementProduct}
                        handleGetItemCard={handleGetItemCard}
                    />
                </CustomModal>
            }
            <CustomModal
                show={showBatchModal}
                handleClose={handleCloseBatchModal}
                title="Add Movement"
                backdrop="static">
                <AddEditMovementsModal
                    getLocationDropdown={getLocationDropdown}
                    handleChangeLocation={handleChangeLocation}
                    handleSaveMovement={handleSaveMovement}
                    error={error}
                />
            </CustomModal>

            <CustomModal
                show={showMovementProductModal}
                handleClose={handleCloseMovementProductModal}
                title="Add Product"
                backdrop="static">
                <AddEditMovementProduct
                    productData={productData}
                    handleChangeProduct={handleChangeProduct}
                    handleSaveProduct={handleSaveProduct}
                    productValue={productValue}
                    quantityInStock={quantityInStock}
                    error={error}
                />
            </CustomModal>
            {getPermission(user.permissions, MOVEMENTS_VIEW) &&
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md="">
                                                <DateRange
                                                    title="Date"
                                                    startDate={dateRange[0]}
                                                    endDate={dateRange[1]}
                                                    range={true}
                                                    handleChange={handleDatesChange} />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Location From"
                                                    placeholder="Location From"
                                                    id={locationFromId}
                                                    data={locations}
                                                    handleChange={(e) => handleLocationFromChange(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Location To"
                                                    placeholder="Location To"
                                                    id={locationToId}
                                                    data={locations}
                                                    handleChange={(e) => handleLocationToChange(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Movement Type"
                                                    placeholder="Movement Type"
                                                    id={typeId}
                                                    data={movementTypes}
                                                    handleChange={(e) => handleTypeChange(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Status"
                                                    placeholder="Status"
                                                    id={statusId}
                                                    data={status}
                                                    handleChange={(e) => handleStatusChange(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>



                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <MovementsTable
                                    isLoading={isLoading}
                                    data={data}
                                    movementsModel={movementsModel}
                                    handleAdd={handleAdd}
                                    handlePrintBatchLabel={handlePrintBatchLabel}
                                    handleBatchProductLabelPDF={handleBatchProductLabelPDF}
                                    handleCloseMovement={handleCloseMovement}
                                    handleInTransitMovement={handleInTransitMovement}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
            {!getPermission(user.permissions, MOVEMENTS_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}
export default Movements;
//import update from 'immutability-helper';
import React, { memo, useCallback, useState, useEffect } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Box } from './Box';
import { Dustbin } from './Dustbin';
import { ItemTypes } from './ItemTypes';
import {
    Spinner,
    Button,
    Form,
    Row,
    Col,
    Card,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { formatCurrency } from 'utils/FormatCurrency.js';

const style = {
   
}

export default memo(function Container(props) {
    const [visible, setVisible] = useState(true);

    const [itemCardColumns, setItemCardColumns] = useState(4);

    useEffect(() => {
        setVisible(true);
        // Hide the message after 2 seconds (1 second solid + 1 second fade out)
        const timer = setTimeout(() => {
            setVisible(false);
        }, 2000);

        // Clean up the timer on unmount
        return () => clearTimeout(timer);
    }, [props.allocationMessage]);

    const handleDrop = useCallback(
        (index, item) => {
            props.addBuyersCollectionAllocation(index, item)
        }
    );

    const handleItemCardColumnChange = (event) => {
        if (isNaN(parseFloat(event.target.value)))
            setItemCardColumns(4);
        else if (event.target.value < 4)
            setItemCardColumns(4);
        else if (event.target.value > 8)
            setItemCardColumns(8);
        else
            setItemCardColumns(event.target.value);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <Row className="buyer-detail">
                <Col className="pr-1" md="8">
                    <Row>
                        <Col md="6">
                            <label><b>Collection Name:</b> {props.data.name}</label>
                        </Col>
                        <Col md="3">
                            <label><b>Brand:</b> {props.data.brand}</label>
                        </Col>
                        <Col md="3">
                            <label><b>Season:</b> {props.data.season}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <label><b>Gender:</b> {props.data.gender}</label>
                        </Col>
                        <Col md="3">
                            <label><b>Styles:</b> {props.collectionLocationDetails.allocatedStyles}</label>
                        </Col>
                        <Col md="3">
                            <label><b>Units:</b> {props.collectionLocationDetails.units}</label>
                        </Col>
                        <Col md="3">
                            <label><b>Cost:</b> {formatCurrency(props.collectionLocationDetails.cost)}</label>
                        </Col>
                    </Row>
                </Col>
                <Col md="4">
                    <Form className="form d-flex flex-wrap">
                        <div className="d-flex align-items-center w-100">
                            <label className="me-3 pt-2">Columns</label>
                            <Form.Control
                                className="me-3"
                                defaultValue={4}
                                type="number"
                                style={{ width: "65px" }}
                                onChange={(e) => handleItemCardColumnChange(e)}
                                min={4}
                                max={8}
                            />
                            <input
                                type="checkbox"
                                checked={props.showDetails}
                                onChange={() => props.setShowDetails(!props.showDetails)}
                                id="custom-switch"
                            />
                            <label className="pt-2">Show details</label>
                        </div>
                    </Form>
                    <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => props.handleDownloadImages(props.data.id, props.data.name)}
                    >
                        Download Images
                    </Button>

                    <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => props.handleExportPDF(props.data.id, props.data.name, props.selectedLocation)}
                    >
                        Download PDF
                    </Button>
                </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Dustbin
                    accept={[ItemTypes.PRODUCT]}
                    onDrop={(item) => handleDrop(0, item.id)}
                    key={0}
                    id={0}
                    name="All"
                    fetchBuyersCollectionProducts={props.fetchBuyersCollectionProducts}
                    collectionId={props.data.id}
                    selectedLocation={props.selectedLocation}
                />
                {props.buyerCollectionLocations.map((index) => {
                    return (
                        <Dustbin
                            accept={[ItemTypes.PRODUCT]}
                            onDrop={(item) => handleDrop(index.id, item.id)}
                            key={index.id}
                            id={index.id}
                            name={index.name}
                            fetchBuyersCollectionProducts={props.fetchBuyersCollectionProducts}
                            collectionId={props.data.id}
                            selectedLocation={props.selectedLocation}
                        />
                    );
                })}
                <label className={`message ${visible ? 'visible' : ''}`}>{props.allocationMessage}</label>
            </Row>
            <div style={{ overflowY: 'auto', flexGrow: 1 }}>
                {props.isLoading ?
                    <div style={{ height: 550 }} className="d-flex justify-content-center align-items-center text-center">
                        <div>
                            <Spinner animation="border" />
                            <p>Loading...</p>
                        </div>
                    </div>
                    :
                    <>
                        {props.buyersCollectionData && props.buyersCollectionData.map((item) => (
                            <Box
                                productData={item}
                                name={item.name}
                                key={item.id}
                                type={ItemTypes.PRODUCT}
                                showDetails={props.showDetails}
                                itemCardColumns={itemCardColumns}
                                handleEditBuyersCollectionProductDetailModal={props.handleEditBuyersCollectionProductDetailModal}
                                handleGetItemCard={props.handleGetItemCard}
                                handleApprovalChange={props.handleApprovalChange}
                                handleDeleteBuyersCollectionProduct={props.handleDeleteBuyersCollectionProduct}
                            />
                        ))}
                        {props.selectedLocation == 0 &&
                            <Col>
                                <Card style={style} className={props.showDetails ? `buyers-${itemCardColumns}-wide` : `buyers-${itemCardColumns}-wide no-details`}>
                                    <Card.Body className="d-flex align-items-center justify-content-center">
                                        <Row>
                                            <Col className="pr-1" style={{ width: "100%" }} md="6" >
                                                <div>
                                                    <Button
                                                        className="btn-link btn-xs pull-right text-success"
                                                        variant="danger"
                                                        onClick={() => props.buyersCollectionProductAddModal()}
                                                    >
                                                        <i className="fas fa-plus" style={{ fontSize: "2rem" }}></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                    </>
                }
            </div>
        </div >
    );
});
import React, { useEffect } from 'react';
import Lottie from 'lottie-react';

export default function Loading(props) {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, [])

    return (
        <>
            <Lottie
                animationData={props.animationData}
                loop={true}
                autoplay={true}
            />
            <center><h3>{props.title}</h3></center>
        </>
    );
}
import React, { useState, useEffect } from "react";
//import Moment from 'react-moment';
// react component used to create charts
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Card,
    Form,
    Container,
    Row,
    Col,
    Button
} from "react-bootstrap";
import { useSelector } from 'react-redux'

import UserTable from '../../components/UserManagement/UserTable';
import UserPage from '../../components/UserManagement/UserPage';
import ChangePassword from '../../components/UserManagement/ChangePassword';
import CustomModal from "components/Shared/CustomModal";
import { ADMIN_USERMANAGEMENT_VIEW, ADMIN_USERMANAGEMENT_EDIT, ADMIN_USERMANAGEMENT_DELETE } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

function AccountManagement(props) {
    const user = useSelector((state) => state.user);

    // ### STATE ###

    const [roles, setRoles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [account, setAccount] = useState(user);
    const [isEdit, setIsEdit] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [error, setError] = useState({
        username: '',
        email: '',
        role: ''
    })
    // ### HANDLERS ###

    const handleChange = (name, value) => {
        setAccount({
            ...account,
            [name]: value
        })
        validateInput(name, value)
    }
    const validateInput = (name, value) => {

        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "email":
                    if (!value) {
                        stateObj[name] = "Please enter an email.";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    }
    const handleShowSave = () => {
        showSweetAlert('warning', `Are you sure to save the user?`, () => { hideSave() });
    }

    const hideSave = () => {

        handleSave()
    }

    const handleSave = async () => {

        const response = await fetch(`/user/update?id=${localStorage.getItem("userId")}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
            method: 'put',
            headers: [
                ["Content-Type", "application/json"],
                ["Content-Type", "text/plain"]
            ],
            body: JSON.stringify(account),
        });
        const isEdit = await response.json();

        if (response.status === 200) {
            await fetchUser();
            showSweetAlert('success', `Account data`);
        } else {
            showSweetAlert('serverError');
        }
        location.reload();
    };
    const handleEdit = () => {
        setIsEdit(true);
    }
    const hideAlert = () => {

    };


    const handlePin = async (id) => {
        try {
            const response = await fetch(`/user/generatenewpin?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'post' });
            const data = await response.json();

            if (data === true) {
                showSweetAlert('saveSuccess', `User`);
            }

            if (body) {
                showSweetAlert('saveSuccess', `User`);
                await fetchData();
            }

            await fetchData();
        } catch (err) {
        }
    }

    // ### DATA FETCHES ###

    const fetchRoles = async () => {
        try {
            let url = `/user/getroles?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();

            setRoles(body);
        } catch (err) {
        }
    }
    const fetchLocations = async () => {
        try {
            let url = `/location/getdropdownlist?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };

            });

            setLocations(data);
        } catch (err) {
        }
    }
    const fetchUser = async () => {
        try {
            let url = `/user/getsingle?id=${localStorage.getItem("userId")}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const user = await response.json();

            dispatch({ type: 'set', user: user });
        } catch (err) {
        }
    }

    const handleSavePasswordSuccess = () => {

        setShowChangePassword(false);
    }



    // ### HOOKS ###

    useEffect(() => {
        fetchRoles();
        fetchLocations();
    }, []);
    useEffect(() => {
        setAccount(user);
    }, [user])

    return (
        <>
            {alert}
            {!showChangePassword &&
                <UserPage
                    userId={account.userId}
                    user={account}
                    roles={roles}
                    locations={locations}
                    handleChange={handleChange}
                    handleShowSave={handleShowSave}
                    error={error}
                    validateInput={validateInput}
                    handleEdit={handleEdit}
                    isEdit={isEdit}
                    setShowChangePassword={setShowChangePassword}
                    handlePin={handlePin}
                />
            }
            {showChangePassword &&
                <ChangePassword
                    setShowChangePassword={setShowChangePassword}
                    handleSavePasswordSuccess={handleSavePasswordSuccess}
                />
            }
        </>
    );
}

export default AccountManagement;

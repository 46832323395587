import React from "react";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import InfiniteScroll from "react-infinite-scroll-component";
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Table,
    Tooltip,
    Spinner,
    Row
} from "react-bootstrap";
import { formatCurrency } from 'utils/FormatCurrency.js';
function BuyersTable(props) {
    const renderTableData = () => {
        return props.data.map((log, index) => {
            const { id, name, brand, gender, itemCount, status, totalValue, totalRrp, season } = log
            return (
                <tr key={id}>
                    <td onClick={() => props.handleCollectionProductDetailModal(log)}>
                        <a href="#id">{name}</a>
                    </td>
                    <td>{brand}</td>
                    {/* <td>{gender}</td>*/}
                    <td>{itemCount}</td>
                    <td>{formatCurrency(totalValue)}</td>
                    <td>{formatCurrency(totalRrp)}</td>
                    <td>{status}</td>
                    <td className="td-actions text-right">
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-981231696">
                                    Export
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="link"
                                onClick={() => props.handleExportTemplate(id, name)}
                            >

                                <i className="fas fa-file-export"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-981231696">
                                    Edit
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="success"
                                onClick={() => props.handleEdit(id)}
                            >
                                <i className="fas fa-edit"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-255158527">
                                    Delete
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="danger"
                                onClick={() => props.handleDeleteBuyersCollection(id)}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </OverlayTrigger>


                    </td>
                </tr>
            )
        })
    }
    return (
        <>
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Row>
                        <Col md="3">
                            <Card.Title as="h4">Buyers Collection</Card.Title>
                        </Col>
                        <Col md="3" style={{ marginTop: '-8px' }}>
                            <CustomSearchBox
                                placeholder="Search"
                                handleChange={(e) => props.handleSearchChange(e)}
                            />
                        </Col>
                        <Col md={6} className="d-flex justify-content-end align-items-center">

                            <Button style={{ marginTop: '-2px' }}
                                className="btn pull-right float-end"
                                variant="primary"
                                onClick={() => props.handleShowModal()}
                            >
                                Add
                            </Button> 
                        </Col>
                        
                    </Row>

                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    {props.isLoading ?
                        <div style={{ height: 550 }} className="d-flex justify-content-center align-items-center text-center">
                            <div>
                                <Spinner animation="border" />
                                <p>Loading...</p>
                            </div>
                        </div> :
                        <>
                            {(props.data && props.data.length == 0) &&
                                (<p
                                    style={{ height: 550 }}
                                    className="d-flex justify-content-center align-items-center text-center"
                                >
                                    No data found.
                                </p>)}
                        </>
                    }
                    {props.data && props.data.length > 0 && !props.isLoading && (
                        < Col md="12">
                            <InfiniteScroll
                                dataLength={props.data.length}
                                hasMore={true}
                                loader={props.data.length >= 20 ? <h6>Loading more data...</h6> : ""}
                                height={550}
                            >
                                <Table className="table-hover table-striped w-full">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Brand</th>
                                            {/* <th>Gender</th>*/}
                                            <th>Item Count</th>
                                            <th>Total Cost</th>
                                            <th>Total RRP</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderTableData()}
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </Col>)}
                </Card.Body>
            </Card>
        </>
    )
}
export default BuyersTable;
import React from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    ListGroup
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
function BuyersCollectionSizeModal(props) {
    console.log("props", props)
    const itemsPerColumn = Math.ceil(props.categorySizes.length / 3);
    const columnOne = props.categorySizes.slice(0, itemsPerColumn);
    const columnTwo = props.categorySizes.slice(itemsPerColumn, itemsPerColumn * 2);
    const columnThree = props.categorySizes.slice(itemsPerColumn * 2);

    return (
        <Form action="" className="form" method="">
            <Card>
                <Card.Body>
                    <Row>
                        <Col className="pr-1" md="6">
                            <Form.Group>
                                <label>Size Scale</label>
                                <CustomSelect
                                    placeholder="Size Scale"
                                    data={props.categoryGroups}
                                    handleChange={(e) => props.handleSizeScaleDropDownChange(e.value)}
                                    id={props.selectedSizeScale != 0 && props.categoryGroups.find((item) => item.id === props.selectedSizeScale).id}
                                />

                            </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                            <Form.Group style={{ marginTop: "27px" }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={props.sizeSelectAll}
                                        onChange={props.handleSelectAllChange}
                                        disabled={props.selectedSizeScale == 0}
                                    />
                                    <div className="LocationExport">Select All</div>
                                </label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="pr-1">
                            <Form.Group>
                                <ListGroup style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                                    <div>
                                        {columnOne.map((item, index) => (
                                            <ListGroup.Item
                                                key={index}
                                                style={{ border: 'none' }}
                                                className="list-group-item"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={props.selectedSizes.includes(item.id)}
                                                    onChange={() => props.handleCheckboxChange(item.id)}
                                                />
                                                <div style={{ marginLeft: '10px' }}>{item.value}</div>
                                            </ListGroup.Item>
                                        ))}
                                    </div>
                                    <div>
                                        {columnTwo.map((item, index) => (
                                            <ListGroup.Item
                                                key={index}
                                                style={{ border: 'none' }}
                                                className="list-group-item"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={props.selectedSizes.includes(item.id)}
                                                    onChange={() => props.handleCheckboxChange(item.id)}
                                                />
                                                <div style={{ marginLeft: '10px' }}>{item.value}</div>
                                            </ListGroup.Item>
                                        ))}
                                    </div>
                                    <div>
                                        {columnThree.map((item, index) => (
                                            <ListGroup.Item
                                                key={index}
                                                style={{ border: 'none' }}
                                                className="list-group-item"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={props.selectedSizes.includes(item.id)}
                                                    onChange={() => props.handleCheckboxChange(item.id)}
                                                />
                                                <div style={{ marginLeft: '10px' }}>{item.value}</div>
                                            </ListGroup.Item>
                                        ))}
                                    </div>
                                </ListGroup>
                            </Form.Group>
                        </Col>
                    </Row>


                </Card.Body>
            </Card>

            <Button
                className="btn-fill float-end"
                variant="primary"
                onClick={() => props.handleUpdateAvailableSizes(props.productId)}
            >
                Save
            </Button>

            <Button
                className="btn-fill float-end"
                variant="info"
                onClick={props.handleCloseSizeModal}
            >
                Cancel
            </Button>
        </Form >
    )
}
export default BuyersCollectionSizeModal;
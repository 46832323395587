import React, { useState, useEffect } from "react";
// react-bootstrap components
import {
    Card,
    Form,
    Container,
    Row,
    Col,
    Button
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'

import SupplierTable from '../../components/Suppliers/SupplierTable';
import CustomModal from '../../components/Shared/CustomModal';
import AddEditSupplier from '../../components/Suppliers/AddEditSupplier';

import { SUPPLIER_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

import { showSweetAlert } from 'components/Shared/CustomAlert';
function Suppliers() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);
    // ### STATE ###

    
    const [data, setData] = useState([]);


    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [supplierId, setSupplierId] = useState(0);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [validation, setvalidation] = useState({
        name: '',
        code: ''
    })
    //hooks
    const handleCloseAddEditModal = () => setShowAddEditModal(false);

    // ### HANDLERS ###

    const handleAdd = () => {
        clearSupplierValidation();
        setSupplierId(0);
        setSelectedSupplier({
            id: 0,
            name: "",
            code: "",
            contactName: "",
            contactNumber: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            county: "",
            postcode: "",
            salesEmail: "",
            customerServiceEmail: ""
        });
        setShowAddEditModal(true);
    }

    const handleEdit = async (id) => {
        clearSupplierValidation();
        try {
            let url = `/supplier/getsingle?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const supplier = await response.json();

            setSupplierId(supplier.id);
            setSelectedSupplier(supplier);
            setShowAddEditModal(true);
        } catch (err) {
        }
    }

    const handleChange = (name, value) => {
        setSelectedSupplier({
            ...selectedSupplier,
            [name]: value
        })
    }


    const handleSave = async () => {
        
        try {
            if (selectedSupplier.name === '' || selectedSupplier.name === null) {
                validation.name = "Please enter a name.";
            }
            if (selectedSupplier.code === '' || selectedSupplier.code === null) {
                validation.code = "Please enter a code.";
            }

            if(selectedSupplier.code !== '' || selectedSupplier.code !== null && selectedSupplier.code.length>2){
                
                validation.code = "Please enter a code of at least 3 characters.";
            }
            if (selectedSupplier.name !== '' && selectedSupplier.name !== null && selectedSupplier.code !== '' && selectedSupplier.code !== null  && selectedSupplier.code.length>2) {
                // Add Supplier
                if (supplierId === 0) {
                    
                    const response = await fetch(`/supplier/add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                        method: 'post',
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"]
                        ],
                        body: JSON.stringify(selectedSupplier),
                    });
                    const body = await response.json();

                    if (response.status === 200) {
                        showSweetAlert('saveSuccess', `Supplier`);
                    }
                    else {
                        showSweetAlert('serverError');
                    }
                }
                // Edit Supplier
                if (supplierId !== 0) {
                    const response = await fetch(`/supplier/update?id=${supplierId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                        method: 'put',
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"]
                        ],
                        body: JSON.stringify(selectedSupplier),
                    });
                    const body = await response.json();

                    if (response.status === 200) {
                        showSweetAlert('saveSuccess', `Supplier`);
                    }
                    else {
                        showSweetAlert('serverError');
                    }
                }
                setSupplierId(0);
                setShowAddEditModal(false);
                await fetchData();
            }
        } catch (err) {
        }
    };
    const handleAddSupplier = () => {
        let message = "Are you sure you want to save the changes to this supplier?";
        if (data.id === 0) message = "Are you sure you want to add this supplier?";
        showSweetAlert('warning', { message }, () => { handleSave() });
    }

    const handleDelete = (id) => {
        showSweetAlert('deleteWarning', `supplier`, () => { successDelete(id) });
    }
    const successDelete = async (id) => {
        try {
            await fetch(`/supplier/delete?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            showSweetAlert('deleteSuccess', `Supplier`);
            await fetchData();
        } catch (err) {
        }
    };
    const clearSupplierValidation = () => {       
        setvalidation({
            name: '',
            code: ''
        })
    };
    const validateInput = (name, value) => {

        setvalidation(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "name":
                    if (!value) {
                        stateObj[name] = "Please enter a name";
                    }
                    break;
                case "code":
                    if (!value) {
                        stateObj[name] = "Please enter a Code";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    }

    // ### DATA FETCHES ###
    const fetchData = async () => {
        try {
            let url = `/supplier/getall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const locations = await response.json();

            setData(locations);
        } catch (err) {
        }
    }

    // ### HOOKS ###
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            {alert}
            <CustomModal
                show={showAddEditModal}
                handleClose={handleCloseAddEditModal}
                title={supplierId === 0 ? "Add Supplier" : "Edit Supplier"}>
                <AddEditSupplier
                    data={selectedSupplier}
                    handleSave={handleSave}
                    handleCancel={handleCloseAddEditModal}
                    handleChange={handleChange}
                    validateInput={validateInput}                    
                    validation={validation}
                    handleAddSupplier={handleAddSupplier}
                />
            </CustomModal>

            {getPermission(user.permissions, SUPPLIER_VIEW) &&
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Container style={{ height: 750 }} fluid>
                                    <Row className="elysium-h100">
                                        <SupplierTable
                                            data={data}
                                            handleAdd={handleAdd}
                                            handleEdit={handleEdit}
                                            handleDelete={handleDelete}
                                        />
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
            {!getPermission(user.permissions, SUPPLIER_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}

export default Suppliers;

import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import CustomSelect from '../Shared/CustomSelect';
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container, Form
    , Dropdown
} from "react-bootstrap";
function PurchaseOrderMappingTable(props) {
    const renderTableData = () => {
        return props.purchaseOrderMappingData.map((PurchaseOrderMapping, index) => {
            var filterData = props.categories.filter(x => x.id === PurchaseOrderMapping.categoryValueId)
            const { id, name, columnStart, formula } = PurchaseOrderMapping
            return (
                <tr key={index}>
                    <td>{name}</td>
                    <td>{columnStart}</td>
                    {filterData.length > 0 ?
                        <td>{filterData[0].name}</td>
                        :
                        <td></td>
                    }
                    <td>{formula}</td>
                    <td className="td-actions">
                        <Button
                            className="btn-link btn-xs"
                            variant="success"
                            onClick={() => props.handleEditPurchaseOrder(id)}
                        >
                            <i className="fas fa-edit"></i>
                        </Button>


                        <Button
                            className="btn-link btn-xs"
                            variant="danger"
                            onClick={() => props.handleDeletePurchaseOrder(id)}
                        >
                            <i className="fas fa-times"></i>
                        </Button>
                    </td>
                </tr>
            )
        });
    }
    return (

        <>
         <style>
        {`
          .table-with-links .card-body .table tr td {
            padding: 0.5rem 0.5rem !important; 
        }
        `}
        </style>
            <Card className="table-with-links elysium-h100">
                <Card.Header>
                    <Card.Title as="h4">
                        <div style={{marginLeft: "6px"}}>Mappings</div>
                    </Card.Title>
                    <div className="d-flex justify-content-between addMaping float-end">
                            <Button
                                className="btn-fill float-end"
                                variant="primary"
                                onClick={() => props.handleAdd()}
                            >
                                Add
                            </Button>
                    </div>
                    <p className="card-category">
                    </p>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    {(props.purchaseOrderMappingData && props.purchaseOrderMappingData.length == 0) &&
                        (<p
                            style={{ height: 120 }}
                            className="d-flex justify-content-center align-items-center text-center"
                        >
                            No data found.
                        </p>
                        )}
                    {(props.purchaseOrderMappingData.length !== 0) &&
                        <Col className="table-hover table-striped w-full">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Column Start</th>
                                        <th>Category</th>
                                        <th>Formula</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTableData()}
                                </tbody>


                            </Table>
                        </Col>
                    }
                </Card.Body>
            </Card>
        </>
    );
}
export default PurchaseOrderMappingTable;
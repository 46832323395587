import React from "react";
import { useState, useEffect, useMemo } from "react";
import CustomModal from "components/Shared/CustomModal";
import PurchaseOrderMappingTable from "components/PurchaseOrderMapping/PurchaseOrderMappingTable";
import AddEditPurchaseOrderMappingModal from "components/PurchaseOrderMapping/AddEditPurchaseOrderMappingModal";
import { Container, Row, Col } from "react-bootstrap";
import { showSweetAlert } from 'components/Shared/CustomAlert';
import { useSelector } from 'react-redux';

import { PURCHASEORDER_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

function PurchaseOrderMapping() {
    const [purchaseOrderMappingData, setpurchaseOrderMappingData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [showEdit, SetShowEdit] = useState(false);
    const user = useSelector((state) => state.user);
    
    const [purchaseOrderId, setPurchaseOrderId] = useState(false);
    const [changePurchaseOrderMappingData, setChangePurchaseOrderMappingData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesDropdown, setCategoriesDropdown] = useState([]);
    const [error, setError] = useState({
        name: '',
        columnStart: '',
        categoryValueId: '',
        formula: ''
    })

    const handleCancel =()=>{
        handleCloseModal();
    }
    const handleUpdatePurchaseOrder = (purchaseOrderId) => {
        showSweetAlert('warning', `Are you sure you want to update this purchase order mapping?`, () => { UpdatePurchaseOrder(purchaseOrderId) });
    }
    const handleDeletePurchaseOrder = (id) => {
        showSweetAlert('deleteWarning', `Purchase Order Mapping`, () => { deleteDeletePurchaseOrder(id) });
    }
    const handleAddPurchaseOrder = () => {
        showSweetAlert('warning', `Are you sure you want to save this purchase order mapping?`, () => { addPurchaseOrder() });
    }
    const handleAdd = () => {
        SetShowEdit(false)
        setError({});
        setChangePurchaseOrderMappingData({
            id: 0,
            name: "",
            columnStart: "",
            categoryValueId: ""
        });
        handleShowModal();
    }
    const handleEditPurchaseOrder = (id) => {
        setError({});
        setPurchaseOrderId(id)
        SetShowEdit(true)
        let PurchaseOrderValue = purchaseOrderMappingData.filter(x => x.id == id);
        setChangePurchaseOrderMappingData(...PurchaseOrderValue);
        handleShowModal(true)
    }
    const handleChange = (name, value) => {
        let item = {
            ...changePurchaseOrderMappingData,
            [name]: value,
        };
        setChangePurchaseOrderMappingData(item);
        validateInput(name, value)
    }
    const validateInput = (name, value) => {
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "name":
                    if (!value) {
                        stateObj[name] = "Please enter a name";
                    }
                    break;

                case "columnStart":
                    if (!value) {
                        stateObj[name] = "Please enter an column number";
                    }
                    break;
                case "categoryValueId":
                    if (value<0) {
                        stateObj[name] = "Please select a category id";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    }
    const addPurchaseOrder = async () => {
        
        if(changePurchaseOrderMappingData.name===""){
        error.name="Please enter a name"
        }
        if(changePurchaseOrderMappingData.columnStart===""){
            error.columnStart="Please enter a column number"
        }
        if(changePurchaseOrderMappingData.categoryValueId===""){
            error.categoryValueId="Please enter a category id"
        }
        if(error.name===''&&error.columnStart===''&&error.categoryValueId===''){
        try {
            let url = `PurchaseOrderMapping/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch((url), {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(changePurchaseOrderMappingData),
            });
            const body = await response.json();
            if (response.status == 200) {
                showSweetAlert('saveSuccess', `Purchase Order`);
                await fetchPurchaseOrderMapping();
                handleCloseModal();
            } else {
                showSweetAlert('serverError');
            }
        } catch (err) {
        }
    }
}
    const UpdatePurchaseOrder = async (purchaseOrderId) => {

        try {
            let url = `PurchaseOrderMapping/Update?id=${purchaseOrderId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch((url), {
                method: 'put',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(changePurchaseOrderMappingData),
            });
            const body = await response.json();
            if (response.status === 200) {
                showSweetAlert('saveSuccess', `Purchase Order`);
                await fetchPurchaseOrderMapping();
                handleCloseModal();
            } else {
                showSweetAlert('serverError');
            }
        } catch (err) {

        }
    }
    const fetchPurchaseOrderMapping = async () => {
        try {
            let url = `PurchaseOrderMapping/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            setpurchaseOrderMappingData(body);
        } catch (err) {
        }
    }
    const fetchCategories = async () => {
        try {
            let url = `Category/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            setCategories(body);

            const data = body?.map((item) => {
                return { id: item.id, value: item.name };
            });

            data.unshift({ id: 0, value: "none" });

            setCategoriesDropdown(data)
        } catch (err) {
        }
    }
    const deleteDeletePurchaseOrder = async (index) => {

        try {
            await fetch(`/PurchaseOrderMapping/Delete?id=${index}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            showSweetAlert('deleteSuccess', `Purchase Order Mapping`);
            fetchPurchaseOrderMapping();
        } catch (err) {
        }
    }


    useEffect(() => {
        fetchPurchaseOrderMapping()
        fetchCategories()
    }, [])
    return (
        <>
            {alert}
            <CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                title={showEdit == false ? "Add Purchase Order Mapping" : "Edit Purchase Order Mapping"}>
                <AddEditPurchaseOrderMappingModal
                    handleAddPurchaseOrder={handleAddPurchaseOrder}
                    handleChange={handleChange}
                    showEdit={showEdit}
                    purchaseOrderMappingData={changePurchaseOrderMappingData}
                    handleUpdatePurchaseOrder={handleUpdatePurchaseOrder}
                    PurchaseOrderId={purchaseOrderId}
                    handleCancel={handleCancel}
                    validateInput={validateInput}
                    error={error}
                    categoriesDropdown={categoriesDropdown}
                />
            </CustomModal>


            {getPermission(user.permissions, PURCHASEORDER_VIEW) &&

                <Container fluid>
                    <Row>
                        <Col md="12">
                            <PurchaseOrderMappingTable
                                purchaseOrderMappingData={purchaseOrderMappingData}
                                handleAdd={handleAdd}
                                handleDeletePurchaseOrder={handleDeletePurchaseOrder}
                                handleEditPurchaseOrder={handleEditPurchaseOrder}
                                categories={categories}
                            />
                        </Col>
                    </Row>
                </Container>
            }
            {!getPermission(user.permissions, PURCHASEORDER_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    )


}
export default PurchaseOrderMapping
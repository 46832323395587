import React, { useEffect, useRef } from 'react';
import {
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";
import BuyerCollectionProductList from "./BuyerCollectionProductList";
import Container from '../React-dnd/Container'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend';

function BuyersCollectionDetailModal(props) {
    const isTablet = /iPad|Android/.test(navigator.userAgent);
    const backend = isTablet ? TouchBackend : HTML5Backend;

    useEffect(() => {
        if (isTablet) {            
            document.documentElement.style.setProperty('--modal-height-tablet', '100vh');
            document.documentElement.style.setProperty('--modal-margin-tablet', '0');
            document.documentElement.style.setProperty('--modal-padding-top', '0');
        } else {
            document.documentElement.style.setProperty('--modal-width', '1200px');
        }
    }, []);

    return (
        <DndProvider backend={backend}>
            <Container
                data={props.data}
                showDetails={props.showDetails}
                setShowDetails={props.setShowDetails}
                buyerCollectionLocations={props.buyerCollectionLocations}
                collectionLocationDetails={props.collectionLocationDetails}
                buyersCollectionData={props.buyersCollectionData}
                buyersCollectionProductAddModal={props.buyersCollectionProductAddModal}
                handleEditBuyersCollectionProductDetailModal={props.handleEditBuyersCollectionProductDetailModal}
                addBuyersCollectionAllocation={props.addBuyersCollectionAllocation}
                fetchBuyersCollectionProducts={props.fetchBuyersCollectionProducts}
                allocationMessage={props.allocationMessage}
                selectedLocation={props.selectedLocation}
                handleGetItemCard={props.handleGetItemCard}
                isLoading={props.isLoading}
                handleApprovalChange={props.handleApprovalChange}
                handleDeleteBuyersCollectionProduct={props.handleDeleteBuyersCollectionProduct}
                handleDownloadImages={props.handleDownloadImages}
                handleExportPDF={props.handleExportPDF}
            />
        </DndProvider>
    )
}

export default BuyersCollectionDetailModal;


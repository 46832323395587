import React, { useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col
} from "react-bootstrap";

function AddEditCategoryModal(props) {
    useEffect(() => {
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, []);

    return (
        <>
            <Form action="" className="form" method="">
                <Row>
                    <Col className="pr-1" md="6">
                        <Form.Group>
                            <label>Category Name</label>
                            <Form.Control
                                placeholder="Category Name"
                                type="text"
                                value={props.data.name}
                                onChange={(e) => props.handleChange("name", e.target.value)}
                            />
                        </Form.Group>

                    </Col>
                </Row>

                <Button
                    className="btn-fill float-end"
                    variant="primary"
                    onClick={props.handleSave}
                >
                    Save
                </Button>

                <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={props.handleCancel}
                >
                    Cancel
                </Button>
                <div className="clearfix"></div>
            </Form >
        </>
    );
}

export default AddEditCategoryModal;

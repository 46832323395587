import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip, Table
} from "react-bootstrap";
import CustomSearchBox from "components/Shared/CustomSearchBox";

import { bool } from "prop-types";

function CategoryValues(props) {
    const [searchKey, setSearchKey] = useState("");
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const SearchStyle = {
        marginTop: "-35px",
        marginLeft: "245px",
    };

    const handleSearchKey = (value) => {
        setSearchKey(value);
    }

    useEffect(() => {
        if (searchKey != "") {
            const filterData = originalData.filter((item) => {
                return item.name.toLowerCase().includes(searchKey.toLowerCase());
            });
            setData(filterData);
        }
        else {
            setData(originalData);
        }
    }, [searchKey])

    useEffect(() => {
        setData(props.data);
        setOriginalData(props.data);
        if(searchKey != "")
            handleSearchKey(searchKey);
    }, [props.data])

    const cellWidth = () => {
        var count = 3;
        if (props.requiresSupplierId) {
            count = count + 1;
        }
        if (props.requiresSizeGroupId) {
            count = count + 1;
        }
        return 100 / count;
    }

    const renderTableData = () => {
        return data.map((categoryValue, index) => {
            const { id, name, code, value, requiresSupplierId, supplierId, supplierName, requiresSizeGroupId, sizeGroupId, sizeGroupName, categoryId } = categoryValue
            return (
                <tr key={index}>
                    <td style={{ width: `${cellWidth()}%` }}>{name}</td>
                    {categoryId == 14 ?
                        <td style={{ width: `${cellWidth()}%` }}>{value}</td>
                        :
                        <td style={{ width: `${cellWidth()}%` }}>{code}</td>
                    }
                    {requiresSupplierId && <td style={{ width: `${cellWidth()}%` }}>{supplierName}</td>}
                    {requiresSizeGroupId && <td style={{ width: `${cellWidth()}%` }}>{sizeGroupName}</td>}
                    <td style={{ width: `${cellWidth()}%` }} className="td-actions">
                        <Button
                            className="btn-link btn-xs"
                            variant="success"
                        >
                            <i className="fas fa-edit" onClick={() => props.handleEdit(id)}></i>
                        </Button>


                        <Button
                            className="btn-link btn-xs"
                            variant="danger"
                        >
                            <i className="fas fa-times" onClick={() => props.handleDelete(id)}></i>
                        </Button>
                    </td>
                </tr>
            )
        });
    }

    return (
        <>

            <div className="d-flex justify-content-between">Values
                <CustomSearchBox
                    placeholder="Search"
                    value={searchKey}
                    handleChange={handleSearchKey}
                    handleClear={() => setSearchKey("")}
                />
                <Button
                    className="btn-fill"
                    variant="primary"
                    onClick={props.handleAdd}
                >
                    Add
                </Button>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: `${cellWidth()}%` }}>Name</th>
                        {
                            props.data[0]?.categoryId == 14 ?
                                <th style={{ width: `${cellWidth()}%` }}>Value</th>
                                :
                                <th style={{ width: `${cellWidth()}%` }}>Code</th>
                        }
                        {props.requiresSupplierId && <th style={{ width: `${cellWidth()}%` }}>Supplier</th>}
                        {props.requiresSizeGroupId && <th style={{ width: `${cellWidth()}%` }}>Size Group</th>}
                        <th style={{ width: `${cellWidth()}%` }}>Actions</th>
                    </tr>
                </thead>
            </Table>
            <div className="table-data">
                <Table>
                    <tbody >
                        {renderTableData()}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default CategoryValues;

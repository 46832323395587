import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { showSweetAlert } from 'components/Shared/CustomAlert';
import CustomModal from "components/Shared/CustomModal.js";
import UpdateLocationPriorityModal from "./UpdateLocationPriorityModal.js";
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container
} from "react-bootstrap";

import { LOCATION_EDIT, LOCATION_DELETE } from '../../constants/Permissions.js';
import { getPermission } from '../../utils/Permissions.js';

function LocationsTable(props) {
    const user = useSelector((state) => state.user);
    

    const handlePermission = () => {
        showSweetAlert('unauthorized');
    }
    const renderTableData = (permissionEdit, permissionDelete) => {
        return props.data.map((data, index) => {
            const { id, name, code, contactNumber, addressLine1, addressLine2, city, county, postcode, email, managerEmail, assistantManagerEmail, ignoreImportExport, has4by4Printer, has2by1Printer, priorityOrder, shopifyId, sortOrder } = data
            return (
                <tr key={`Location${index}${id}`}>
                    <td className="text-center">{id}</td>
                    <td>{name}</td>
                    <td>{code}</td>
                    <td>{contactNumber}</td>
                    <td>{addressLine1}</td>
                    <td>{email}</td>
                    <td>{managerEmail}</td>
                    <td>{assistantManagerEmail}</td>
                    <td>{priorityOrder}</td>
                    <td>{sortOrder}</td>
                    <td>{shopifyId}</td>
                    <td>{ignoreImportExport ? "Yes" : "No"}</td>
                    <td>{has4by4Printer ? "Yes" : "No"}</td>
                    <td>{has2by1Printer ? "Yes" : "No"}</td>
                    <td className="td-actions text-right">
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-981231696">
                                    {`Edit ${props.itemType}`}
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="success"
                                onClick={permissionEdit ? () => props.handleEdit(id) : () => handlePermission()}
                            >
                                <i className="fas fa-edit"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-255158527">
                                    {`Delete ${props.itemType}`}
                                </Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs btn-action"
                                variant="danger"
                                onClick={permissionDelete ? () => props.handleDelete(id) : () => handlePermission()}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        })
    }
    return (
        <>
            {alert}
            <Card className="table-with-links elysium-h100">
                <Card.Header>
                    <Card.Title as="h4" className="d-flex justify-content-between align-items-center">
                        {props.title}
                        <Button onClick={props.handleLocationPriorityButtonClick}
                            style={{ marginLeft: "70%" }}
                            className="btn-fill"
                            variant="primary"
                        >
                            Location Priority
                        </Button>
                        <Button onClick={props.handleLocationOrderButtonClick}
                            className="btn-fill"
                            variant="primary"
                        >
                            Location Order
                        </Button>
                        {getPermission(user.permissions, LOCATION_EDIT) ?
                            <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip id="tooltip-255158527">{`Add ${props.itemType}`}</Tooltip>
                                }
                            >

                                <Button
                                    className="btn-fill"
                                    variant="primary"
                                    onClick={() => props.handleAdd()}
                                >
                                    Add
                                </Button>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip id="tooltip-255158527">{`Add ${props.itemType}`}</Tooltip>
                                }
                            >
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={() => props.handlePermission()}
                                >
                                    Add
                                </Button>
                            </OverlayTrigger>
                        }

                    </Card.Title>
                    <p className="card-category">
                    </p>
                </Card.Header>
                <Card.Body className="table-full-width elysium-h90">
                    <Col className="overflow-auto elysium-h100">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Contact Number</th>
                                    <th>Address</th>
                                    <th>Email</th>
                                    <th>Manager Email</th>
                                    <th>Assistant Manager Email</th>
                                    <th>Priority</th>
                                    <th>Sort Order</th>
                                    <th>Shopify Id</th>
                                    <th>Exclude From Export</th>
                                    <th>4 x 4</th>
                                    <th>2.3 x 1.3</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData(getPermission(user.permissions, LOCATION_EDIT), getPermission(user.permissions, LOCATION_DELETE))}
                            </tbody>
                        </Table>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
}

export default LocationsTable;


import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
    Form,
    Card,
    Col,
    Button,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Dropdown
} from "react-bootstrap";
import CustomSelect from "../Shared/CustomSelect";
import { showSweetAlert } from 'components/Shared/CustomAlert';


function InventoryModal(props) {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, [])

    const [isValidation, setIsValidation] = useState(false);
    const [reason, setReason] = useState("");
    const [selectedInventory, setSelectedInventory] = useState(props.selectedInventory);
    


    const handleInventoryChange = (e) => {
        var { value, name } = e.target;
        const updatedSizes = selectedInventory.sizesInStockAndSold.map((size) => {
            if (size.productId == name) {
                return { ...size, inStock: value };
            }
            return size;
        });

        setSelectedInventory({ ...selectedInventory, sizesInStockAndSold: updatedSizes });
    };

    const handleReason = (value) => {
        if (value === "" || value === undefined || value === null) {
            setIsValidation(true)
        } else {
            setIsValidation(false)
            setReason(value)
        }

    }

    const handleSaveAlert = () => {
        if (reason === "" || reason === undefined || reason === null) {
            setIsValidation(true);
        } else {
            setIsValidation(false);
            showSweetAlert('warning', `Are you sure you want to update the quantity available at ${selectedInventory.locationName}?`, () => { handleUpdateInventory() });
        }
    }

    const handleUpdateInventory = async () => {
        try {
            const response = await fetch(`/Inventory/ChangeQuantity?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&reason=${reason}`, {
                method: 'put',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(selectedInventory),
            });

            if (response.status == 200) {
                setReason("")
                showSweetAlert('saveSuccess', `Quantity`);


                //handleGetItemCards(inventory.productId, inventory.locationName)
                //handleInventoryViewModelHide()
                props.handleHideInventoryModal();
            }
            else {
                showSweetAlert('serverError');
            }
        } catch (err) {
            showSweetAlert('serverError');
        }
    }

    return (
        <Form action="" className="form" method="">
            {alert}
            <Card.Body>
                <Row>
                    <h4>Edit Inventory - {selectedInventory.locationName}</h4>
                    {selectedInventory.sizesInStockAndSold.length > 0 && selectedInventory.sizesInStockAndSold.map((item) => (
                        <Col md="6" key={item.productId}>
                            <Form.Group>
                                <label>{item.sizeName}</label>
                                <Form.Control
                                    className=""
                                    placeholder=""
                                    type="number"
                                    value={item.inStock}
                                    name={item.productId}
                                    min={0}
                                    onChange={(e) => handleInventoryChange(e)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col md="6">
                        <Form.Group>
                            <label>Reason</label>
                            <CustomSelect
                                className="select-dropdown"
                                value={String(reason)}
                                data={[
                                    { id: "Stock Correction", value: "Stock Correction" },
                                    { id: "Stolen Goods", value: "Stolen Goods" },
                                    { id: "Stock Take Correction", value: "Stock Take Correction" },
                                ]}
                                handleChange={(e) => handleReason(e.value)}>
                            </CustomSelect>
                            {isValidation && <h5 className="text-danger">Reason is required</h5>}
                        </Form.Group>
                    </Col>


                </Row>
                <div className="d-flex justify-content-end pt-3">
                    <Button
                        className="btn-fill"
                        variant="info"
                        onClick={props.handleHideInventoryModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-fill"
                        variant="primary"
                        onClick={handleSaveAlert}
                    >
                        Save
                    </Button>
                </div>

            </Card.Body>
        </Form>

    )
}
export default InventoryModal
import { MsalAuthProvider, LoginType } from 'react-aad-msal';


export const authProvider = new MsalAuthProvider(
    {
        auth: {
            clientId: "57e11b09-1b17-4cd8-959a-e948dbaf1efd", //Application (client) ID
            authority: "https://login.microsoftonline.com/4f8096af-9968-4ff6-9d98-87cbc6fc71fd", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
    },
    {
        scopes: ['dc60f365-ea3f-4638-988e-0037951817e7/access_as_user']
    },
    LoginType.Redirect
);
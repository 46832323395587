import React from 'react';
import { useMemo } from 'react';
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react-bootstrap components

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container
} from "react-bootstrap";

import { LOCATION_EDIT, LOCATION_DELETE } from '../../constants/Permissions.js';
import { getPermission } from '../../utils/Permissions.js';

export default function CustomTable(props) {
    const user = useSelector((state) => state.user);


    const [keys, setKeys] = useState(null);


    // get table column
    if (props.data !== undefined || props.data !== null) {
        setKeys(Object.keys(props.data[0]));
    }

    // get table heading data
    const renderHeaderData = () => {
        return keys.map((key, index) => {
            return <th key={key}>{key.toUpperCase()}</th>
        })
    }

    const handlePermission = () => {
        showSweetAlert('unauthorized');
    }

    const renderRow = (data) => {
        return keys.map((key, index) => {
            return <td key={data[key]}>{data[key]}</td>
        })
    }

    const renderTableData = (permissionEdit, permissionDelete) => {
        var items = props.data;
        return props.data.map((row, index) => {
            return <tr key={index}><renderRow data={row} /></tr>
        })

        //    return (
        //        <tr key={`Location${index}${id}`}>
        //            {
        //                column.map((v) => {
        //                    return <td>{data[v]}</td>
        //                })
        //            }
        //            {/*<td className="td-actions text-right">
        //                <OverlayTrigger
        //                    href="#pablo"
        //                    onClick={(e) => e.preventDefault()}
        //                    overlay={
        //                        <Tooltip id="tooltip-981231696">
        //                            {`Edit ${props.itemType}`}
        //                        </Tooltip>
        //                    }
        //                >
        //                    <Button
        //                        className="btn-link btn-xs btn-action"
        //                        variant="success"
        //                        onClick={permissionEdit ? () => props.handleEdit(id) : () => handlePermission()}
        //                    >
        //                        <i className="fas fa-edit"></i>
        //                    </Button>
        //                </OverlayTrigger>
        //                <OverlayTrigger
        //                    href="#pablo"
        //                    onClick={(e) => e.preventDefault()}
        //                    overlay={
        //                        <Tooltip id="tooltip-255158527">
        //                            {`Delete ${props.itemType}`}
        //                        </Tooltip>
        //                    }
        //                >
        //                    <Button
        //                        className="btn-link btn-xs btn-action"
        //                        variant="danger"
        //                        onClick={permissionDelete ? () => props.handleDelete(id) : () => handlePermission()}
        //                    >
        //                        <i className="fas fa-times"></i>
        //                    </Button>
        //                </OverlayTrigger>
        //            </td>*/}
        //        </tr>
        //    )
        //})
    }

    return (
        <>
            {alert}
            <Card className="table-with-links elysium-h100">
                <Card.Header>
                    <Card.Title as="h4" className="d-flex justify-content-between align-items-center">
                        {props.title}
                        {getPermission(user.permissions, LOCATION_EDIT) ?
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handleAdd()}
                            >
                                Add
                            </Button>
                            :
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handlePermission()}
                            >
                                Add
                            </Button>
                        }
                    </Card.Title>
                    <p className="card-category">
                    </p>
                </Card.Header>
                <Card.Body className="table-full-width elysium-h90">
                    <Col className="overflow-auto elysium-h100">
                        <Table>
                            <thead>
                                <tr>
                                    {renderHeaderData()}
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData(getPermission(user.permissions, LOCATION_EDIT), getPermission(user.permissions, LOCATION_DELETE))}
                            </tbody>
                        </Table>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
}
import React, { useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
function AddEditMovementProduct(props) {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '800px');
    }, [])

    return (

        <Form action="" className="form" method="">
            <Card>
                <Row>
                    <Col className="pr-1" md="8">
                        <Form.Group>
                            <label>Product name</label>
                            <CustomSelect
                                placeholder="Product name"
                                data={props.productData}
                                handleChange={(e) => props.handleChangeProduct("productId", e.value)}
                                withAll={false}
                            />
                            {props.error.productId && <label style={{ color: "red" }} className='err'>{props.error.productId}</label>}
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>Available Stock</label>
                            <Form.Control
                                type="number"
                                value={props.quantityInStock}
                                disabled
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="pr-1" md="2">
                        <Form.Group>
                            <label>Quantity</label>
                            <Form.Control
                                type="number"
                                value={props.productValue.quantity}
                                onChange={(e) => props.handleChangeProduct("quantity", e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Card>
            <Button
                className="btn-fill float-end"
                variant="primary"
                onClick={props.handleSaveProduct}
            >
                Save
            </Button>
        </Form >
    )
} export default AddEditMovementProduct;
import React, { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";

export default function CustomSearchBox(props) {
    const [inputValue, setInputValue] = useState(props.value || '');
    const [timer, setTimer] = useState(null);

    // This effect runs when the component unmounts, to clear any remaining timers.
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    const changeHandler = (event) => {
        // Clear the timer if it's still pending when the user types again
        if (timer) {
            clearTimeout(timer);
        }

        const newValue = event.target.value;

        // Update the local state
        setInputValue(newValue);

        // Set a new timer
        const newTimer = setTimeout(() => {
            props.handleChange(newValue);
        }, 1000); // 1 second delay

        setTimer(newTimer);
    };

    // Clear input value when clear button is pressed
    const handleClear = () => {
        setInputValue('');
        props.handleClear();
    }

    return (
        <>
            <h5 className="title">{props.title}</h5>
            <div className="position-relative d-flex align-items-center">
                <Form.Control
                    className="form-control"
                    type="text"
                    id=""
                    placeholder={props.placeholder}
                    value={inputValue} // controlled component
                    onChange={changeHandler} // use the local change handler
                ></Form.Control>
                <i
                    style={{ right: 5 }}
                    className="nc-icon nc-simple-remove position-absolute cursor-pointer"
                    onClick={handleClear} // use the local clear handler
                ></i>
            </div>
        </>
    );
}

import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import CustomSelect from '../Shared/CustomSelect';

function CategoryGroups(props) {
    return (
        <>
            <Row className="mb-5" md="12">
                <Col className="elysium-h100">

                    <Row className="mb-2 sticky-scroll">
                        <Col md="12"><b>Category Groups</b></Col>
                        <Row>
                            {props.data.length > 0 ?
                                <>
                                    <Col md="6">
                                        <CustomSelect
                                            placeholder="Category Group"
                                            data={props.data}
                                            value={props.selected?.id !== null ? props.selected?.id : 0}
                                            handleChange={(e) => props.handleSelect(e.value)}
                                            withAll
                                        />
                                    </Col>
                                    {props.selected?.id !== 0 && props.selected?.id !== undefined ?
                                        <Col md="6">
                                            <OverlayTrigger
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                                overlay={
                                                    <Tooltip id="tooltip-981231696">
                                                        Edit
                                                    </Tooltip>
                                                }
                                            >
                                                <Button
                                                    onClick={() => props.handleEdit()}
                                                    className="btn-link btn-xs"
                                                    variant="success"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                                overlay={
                                                    <Tooltip id="tooltip-981231696">
                                                        Delete
                                                    </Tooltip>
                                                }
                                            >
                                                <Button
                                                    className="btn-link btn-xs"
                                                    variant="danger"
                                                    onClick={() => props.handleDelete()}
                                                >
                                                    <i className="fas fa-times" ></i>
                                                </Button>
                                            </OverlayTrigger>
                                                <Button
                                                    className="btn-fill"
                                                    variant="primary"
                                                    onClick={() => props.handleAdd()}
                                                >
                                                    Add
                                                </Button>
                                        </Col>
                                        :
                                        <Col md="6">
                                            <Button
                                                className="btn-fill"
                                                variant="primary"
                                                    onClick={() => props.handleAdd()}
                                                >
                                                    Add
                                                </Button>
                                        </Col>
                                    }
                                </>
                                :
                                <Col md="6">
                                    <div className="d-flex justify-content-between"><p>No Group in this Category</p>
                                        <Button
                                            className="btn-fill float-end"
                                            variant="primary"
                                            onClick={() => props.handleAdd()}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Row>
                </Col>
            </Row>

        </>
    );
}

export default CategoryGroups;

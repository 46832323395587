import React, { memo, useState, useEffect } from "react";
import { useDrop } from "react-dnd";
const style = {
    height: "30px",
    width: "50px",
    margin: "10px",
    color: "white",
    textAlign: "center",
    lineHeight: "normal",
    float: "left",
    cursor: "pointer"
};
export const Dustbin = memo(function Dustbin({
    accept,
    onDrop,
    name,
    id,
    fetchBuyersCollectionProducts,
    collectionId,
    selectedLocation
}) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const isActive = isOver && canDrop;

    let backgroundColor = "#222";
    if (isActive) {
        backgroundColor = "darkgreen";
    } else if (canDrop) {
        backgroundColor = "darkkhaki";
    }

    if (selectedLocation == id) {
        backgroundColor = "#FFA500";
    }
    return (
        <div ref={drop}
            style={{ ...style, backgroundColor }}
            data-testid="dustbin"
            onClick={() => fetchBuyersCollectionProducts(id, collectionId)}
        >
            {isActive
                ? "Drop"
                : name}
        </div>
    );
});

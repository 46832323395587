import React, { useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card
} from "react-bootstrap";

import { TouchBackend } from 'react-dnd-touch-backend';

function BuyersCollectionProductAddModal(props) {
    useEffect(() => {
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, []);

    const isTablet = /iPad|Android/.test(navigator.userAgent);

    if (isTablet) {
        props.handleAddProductDetail(true);
    }

    return (
        <div className="selectButton">
            <Button
                id="file-browser"
                type="file"
                accept="image/png, image/jpeg"
                className="btn-fill"
                variant="primary"
                onClick={()=>props.handleAddProductDetail(true)}
            >
                File Browser
            </Button>
            {/* // <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple /> */}
            <Button
                className="btn-fill"
                variant="primary"
                onClick={()=>props.handleAddProductDetail(false)}
            >
                No Image
            </Button>
            <Button
                className="btn-fill"
                variant="primary"
                onClick={props.handleCloseBuyersCollectionProductAddModal}
            >
                Cancel
            </Button>
        </div>
    )
}
export default BuyersCollectionProductAddModal;
import React from 'react';
import { useEffect, useState } from 'react';

// react plugin used to create datetimepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// react-bootstrap components
import {
    Form,
} from "react-bootstrap";

export default function DateRange(props) {

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const range = props.range !== undefined ? props.range : false;
    if (range) {
        return (
            <>
                <h5 className="title">{props.title}</h5>
                <Form.Group className="date-range">
                    <DatePicker
                        
                        dateFormat={'dd/MM/yyyy'}
                        selectsRange={true}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        onChange={(value) => props.handleChange(value)}
                    />
                </Form.Group>
            </>
        );
    } else {
        return (
            <>
                <h5 className="title">{props.title}</h5>
                <Form.Group className="date-range">
                    <DatePicker
                        dateFormat={'dd/MM/yyyy'}
                        selected={isValidDate(new Date(props.startDate)) ? new Date(props.startDate) : null}
                        onChange={(value) => props.handleChange(value)}
                    />
                </Form.Group>
            </>
        );
    }
}
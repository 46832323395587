import React from 'react';
import {Modal, Card} from "react-bootstrap";

export default function CustomModal(props) {
    const backdrop = props.backdrop === undefined ? true : props.backdrop;

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                animation={false}
                backdrop={backdrop}
            >
                <Card className="strpied-tabled-with-hover modal-card">
                    <Card.Header>
                        <Modal.Header closeButton className="CloseButton" />
                        <Card.Title as="span">{props.title}</Card.Title>
                    </Card.Header>
                    {props.children}
                </Card>
            </Modal>
        </>
    );
}
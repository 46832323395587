import React, { useEffect } from "react"
import { Formik, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Col,
    Row,
    Form,
    Card
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
import { formatCurrency } from 'utils/FormatCurrency.js';

const validationSchema = Yup.object({
    productId: Yup.string().required('Product is required'),
    quantity: Yup.number().min(1, 'At least 1 is required.'),
    costPrice: Yup.number().min(0, 'Cost price must be 0 or more.'),
    discountPercentage: Yup.number().min(0, 'Discount percentage must be 0 or more.'),
    allocations: Yup.array()
        .of(
            Yup.object().shape({
                quantity: Yup.number()
                    .min(0, "Quantity must be 0 or greater.")
                    .required("Quantity is required.")
                    .integer("Quantity must be an integer."),
            })
        )
        .test("all-quantities-greater-than-zero", "All quantities must be 0 or greater.", (value) => {
            if (!value || !Array.isArray(value)) {
                // If the array is empty or not an array, the test passes
                return true;
            }

            // Check if all quantities are 0 or greater
            return value.every((allocation) => allocation.quantity >= 0);
        }),
    totalAllocation: Yup.number().test(
        "total-allocation-check",
        "Total allocation cannot exceed the quantity.",
        function (value) {
            const { allocations } = this.parent;
            if (!allocations || !Array.isArray(allocations)) {
                // If the 'allocations' array is empty or not an array, the test passes
                return true;
            }

            const totalAllocation = allocations.reduce((total, allocation) => {
                // Check if the purchaseOrderLineId is not equal to 0
                if (allocation.purchaseOrderLineId !== 0) {
                    return total + allocation.quantity;
                }
                // If purchaseOrderLineId is 0, don't include the quantity in the total
                return total;
            }, 0);
            return totalAllocation <= this.parent.quantity; // Compare with the main quantity field
        }
    ),
    totalAllocated: Yup.number().test(
        "total-allocated-check",
        "Total already allocated is higher the quantity.",
        function (value) {
            const { allocations } = this.parent;
            if (!allocations || !Array.isArray(allocations)) {
                // If the 'allocations' array is empty or not an array, the test passes
                return true;
            }
            const totalAllocated = allocations.reduce((total, allocation) => total + allocation.allocated, 0);

            return totalAllocated <= this.parent.quantity; // Compare with the main quantity field
        }
    ),
});

function AddEditPurchaseOrderLineModalForm(formik) {
    const handleProductChange = (e, formik) => {
     //   formik.setValues({ ...formik.values, productId: e.value, productName: e.label })
        formik.initialValues.handleProductLineChange("productId", e);
    }
    return (
        <Form onSubmit={formik.handleSubmit}>
            <Row>
                <Col md="12">
                    <Form.Group>
                        <label htmlFor="firstName">Product</label>
                        <div>
                            <CustomSelect
                                name="productId"
                                placeholder="Product"
                                id={formik.values.productId}
                                value={formik.values.products.id}
                                data={formik.values.products}
                                handleChange={(e) => handleProductChange(e, formik)}
                            />
                            <ErrorMessage
                                name="productId"
                                component="div"
                                className="text-danger"
                            />
                        </div>
                    </Form.Group>
                </Col>

                <Col md="6">
                    <Form.Group>
                        <label>Qty</label>
                        <Form.Control
                            placeholder="Qty"
                            name="quantity"
                            type="number"
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        ></Form.Control>
                        <ErrorMessage
                            name="quantity"
                            component="div"
                            className="text-danger"
                        />
                    </Form.Group>
                </Col>

                <Col md="6">
                    <Form.Group>
                        <label>Cost Price</label>
                        <Form.Control
                            placeholder="Cost Price"
                            name="costPrice"
                            type="number"
                            value={formik.values.costPrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        ></Form.Control>
                        <ErrorMessage
                            name="costPrice"
                            component="div"
                            className="text-danger"
                        />
                    </Form.Group>
                </Col>

                <Col md="6">
                    <Form.Group>
                        <label>Discount Percentage</label>
                        <Form.Control
                            placeholder="Discount Percentage"
                            name="discountPercentage"
                            type="number"
                            value={formik.values.discountPercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        ></Form.Control>
                        <ErrorMessage
                            name="discountPercentage"
                            component="div"
                            className="text-danger"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                {formik.values.allocations.map((allocation, index) => (

                    allocation.purchaseOrderLineId != 0 ? (
                        <Col className="pr-1 productCol" md="6" key={index}>
                            <Form.Group>
                                <label>
                                    {allocation.locationCode + " - " + allocation.allocated + " allocated"}
                                </label>
                                <Form.Control
                                    placeholder="0"
                                    name={`allocations[${index}].quantity`} // Use array index in the field name
                                    type="number"
                                    value={allocation.quantity}
                                    onChange={(event) => {
                                        const newAllocations = [...formik.values.allocations];
                                        newAllocations[index] = {
                                            ...newAllocations[index],
                                            quantity: parseInt(event.target.value),
                                        };
                                        formik.setFieldValue("allocations", newAllocations);
                                    }}
                                    onBlur={formik.handleBlur}
                                ></Form.Control>
                            </Form.Group>

                            <ErrorMessage
                                name={`allocations[${index}].quantity`} // Use array index in the name
                                component="div"
                                className="text-danger"
                            />
                        </Col>
                    ) : null
                ))}
            </Row>
            <Row>
                <Col className="pr-1 productCol" md="6">
                    <div className="text-danger">{formik.errors.totalAllocation}</div>
                </Col>
            </Row>
            <Row>
                <Col className="pr-1 productCol" md="6">
                    <div className="text-danger">{formik.errors.totalAllocated}</div>
                </Col>
            </Row>
            <Button
                className="see-more-btn float-end"
                variant="primary"
                onClick={() => formik.handleSubmit()}
            >
                {formik.values.isEdit ? "Update" : "Save"}
            </Button>
            <Button
                className="btn-fill float-end"
                variant="info"
                onClick={() => formik.values.handleCancel()}
            >
                Cancel
            </Button>
        </Form>
    )
}


function AddEditPurchaseOrderLineModal(props) {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '500px');
    }, [])
    return (
        <Formik
            initialValues={{ ...props.purchaseOrderLineChange, products: props.products, isEdit: props.isEdit, handleProductLineChange: props.handleProductLineChange, handleCancel: props.handleCancel  }}
            validationSchema={validationSchema}
            component={AddEditPurchaseOrderLineModalForm}
            enableReinitialize 
            onSubmit={async (values, actions) => {
                if (props.isEdit) {
                    await props.handleUpdatePurchaseOrderLine(values);
                }
                else {
                    await props.handleSavePurchaseOrderLine(values);
                }
                actions.setSubmitting(false);
            }}
            
        />
    )
}

export default AddEditPurchaseOrderLineModal;

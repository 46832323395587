import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react-bootstrap components

export default function CustomAsyncSelect(props) {

    const { id, data, withAll } = props;

    const selectOptions = useMemo(() => {
        const options = data.map((e) => {

            return {
                value: e.id,
                label: e.value
            }
        });

        if (withAll)
            return [{
                value: 0,
                label: "ALL",
            }].concat(options);

        return options;
    }, [data])

    return (
        <>
            <h5 className="title">{props.title}</h5>
            <Select
                isMulti
                className="react-select primary custom-select"
                classNamePrefix="react-select"
                name="customAsyncSelect"
                //onChange={props.handleChange}
                options={selectOptions}
                //placeholder={props.title + " select"}
            />
        </>
    );
}
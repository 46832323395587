export const formatCurrency = (value) => {
    if (value !== undefined && value !== null) {
        const formattedValue = value.toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP'
        });

        return formattedValue;
    }
    return null;
}

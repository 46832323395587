import React from "react";
import ImageGallery, { LeftNav, RightNav } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-image-gallery/styles/css/image-gallery.css';

function BuyersCollectionImageGallery(props) {
    const images = props.items.map((base64Image) => ({
        original: `data:image/jpeg;base64,${base64Image}`,
        originalHeight: "200px"
    }));

    // Define the onClick function to handle navigation
    const handleNavigation = (event) => {
        const direction = event.target.getAttribute('data-direction');
        if (direction === 'left') {
            // Handle left navigation 
            imageGalleryRef.current.slideToIndex(imageGalleryRef.current.getCurrentIndex() - 1);
        } else if (direction === 'right') {
            // Handle right navigation
            imageGalleryRef.current.slideToIndex(imageGalleryRef.current.getCurrentIndex() + 1);
        }
    };

    // Ref for the ImageGallery component to access its methods
    const imageGalleryRef = React.useRef(null);

    // Custom render for left and right navigation icons
    const customRenderItem = (item) => {
        return (
            <>
                <img className="image-gallery-image" src={item.original} alt={item.originalAlt} />
                {images.length > 1 &&
                    <>
                        <span className="image-gallery-left-nav" onClick={handleNavigation} data-direction="left">
                            &lt; 
                        </span>
                        <span className="image-gallery-right-nav" onClick={handleNavigation} data-direction="right">
                            &gt; 
                        </span>
                    </>
                }
            </>
        );
    };

    return (
        <div className="custom-image-gallery">
            <ImageGallery
                ref={imageGalleryRef}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                renderItem={customRenderItem}
                showNav={false}
            />
        </div>
    );
}
export default BuyersCollectionImageGallery;